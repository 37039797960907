import React, { useState } from "react";
export const TextFieldNew = ({
  label,
  className,
  type,
  value,
  onChange,
  helpText,
  disabled,
  errorText,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  function handleFocus() {
    setIsFocused(true);
  }

  function handleBlur(event) {
    if (event.target.value === "") {
      setIsFocused(false);
    }
  }

  const labelStyle = {
    position: "absolute",
    top: isFocused || value ? "-0.55rem" : "0.5rem",
    left: isFocused || value ? "1.5rem" : "1rem",
    pointerEvents: "none",
    transition: "all 0.2s ease-out",
    textTransform: "uppercase",
    fontSize: isFocused || value ? "0.75rem" : "1rem",
    color:
      isFocused || value
        ? "var(--fontColorPrimary)"
        : "var(--fontColorPrimary)",
  };

  const inputStyle = {
    textAlign: "center",
    backgroundColor: "transparent",
    height: "40px",
    width: "246px",
    fontSize: "16px",
    fontWeight: 600,
    color: "var(--fontColorPrimary)",
    border: "1px solid var(--inputBorderOverlap)",
    borderRadius: "10px",
    outline: "2px solid transparent",
    outlineOffset: "2px",
    cursor: "text",
    padding: "0.75rem",
    textTransform: "uppercase",
  };

  return (
    <div style={{ position: "relative", marginBottom: "1rem" }}>
      {type === "number" && (
        <input
          disabled={disabled}
          type="text"
          pattern="[0-9]*"
          inputMode="numeric"
          style={inputStyle}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}
      {(!type || type === "text") && (
        <input
          disabled={disabled}
          type="text"
          style={inputStyle}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}
      <label style={labelStyle}>{label}</label>
      {errorText && (
        <p
          style={{
            color: "var(--errorColor)",
            fontSize: "10px",
            margin: "3px 0 0 3px",
            width: "240px",
          }}
        >
          {errorText}
        </p>
      )}
      {helpText && !errorText && (
        <p
          style={{
            color: "var(--fontColorHint)",
            fontSize: "10px",
            margin: "3px 0 0 3px",
            width: "240px",
          }}
        >
          {helpText}
        </p>
      )}
    </div>
  );
};
