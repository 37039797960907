import React, { useEffect, useState } from "react";
import { addEvent } from "../api/Events/EventsApi";
import { useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import ClipLoader from "react-spinners/ClipLoader";
import StorageManager from "../utils/StorageManager";
import "./ConfirmData.scss";
import { TextFieldNew } from "../utils/TextField/TextFieldNew";
import { DateTextField } from "../utils/TextField/DateTextField";
import { Button4, Button5 } from "./../utils/Buttons/Buttons";
import { infoMessage } from "../utils/ErrorHandler/errorHandler";
import { addDays, addMonths, addYears } from "date-fns";

export default function ConfirmData({ api }) {
  let history = useHistory();
  const query = useQuery();
  const UrlCode = query.get("code");

  const [documentData, setDocumentData] = useState(null);
  // Contry Config
  const [country, setCountry] = useState("");
  const [documentType, setDocumentType] = useState("");

  // Cedula info
  const [nombres, setNombres] = useState("");
  const [nombresLabel, setNombresLabel] = useState("");
  const [nombresErrorMessage, setNombresErrorMessage] = useState("");

  const [apellidos, setApellidos] = useState("");
  const [apellidosLabel, setApellidosLabel] = useState("");
  const [apellidosErrorMessage, setApellidosErrorMessage] = useState("");

  const [documentNumber, setDocumentNumber] = useState("");
  const [documentNumberHint, setDocumentNumberHint] = useState("");
  const [documentNumberKeyboard, setDocumentNumberKeyboard] = useState("text");
  const [documentNumberLabel, setDocumentNumberLabel] = useState("");
  const [documentNumberErrorMessage, setDocumentNumberErrorMessage] =
    useState("");
  const [documentNumberCanEdit, setDocumentNumberCanEdit] = useState(true);

  const [expeditionCity, setExpeditionCity] = useState("");
  const [expeditionCityHint, setExpeditionCityHint] = useState("");
  const [expeditionCityLabel, setExpeditionCityLabel] = useState("");
  const [expeditionCityErrorMessage, setExpeditionCityErrorMessage] =
    useState("");

  const [birthPlace, setBirthPlace] = useState("");
  const [birthPlaceHint, setBirthPlaceHint] = useState("");
  const [birthPlaceLabel, setBirthPlaceLabel] = useState("");
  const [birthPlaceErrorMessage, setBirthPlaceErrorMessage] = useState("");

  const [birthState, setBirthState] = useState("");
  const [birthStateHint, setBirthStateHint] = useState("");
  const [birthStateLabel, setBirthStateLabel] = useState("");
  const [birthStateErrorMessage, setBirthStateErrorMessage] = useState("");

  const [bloodType, setBloodType] = useState("");
  const [bloodTypeLabel, setBloodTypeLabel] = useState("");
  const [bloodTypeHint, setBloodTypeHint] = useState("");
  const [bloodTypeErrorMessage, setBloodTypeErrorMessage] = useState("");

  const [sexo, setSexo] = useState("");
  const [sexoHint, setSexoHint] = useState("");
  const [sexoLabel, setSexoLabel] = useState("");
  const [sexoErrorMessage, setSexoErrorMessage] = useState("");

  const [birthDate, setBirthDate] = useState(new Date());
  const [birthDateErrorMessage, setBirthDateErrorMessage] = useState("");
  const [birthDateHint, setBirthDateHint] = useState("");
  const [birthDateLabel, setBirthDateLabel] = useState("");
  const [birthDateString, setBirthDateString] = useState(
    getStringFromDate(new Date())
  );

  const [expeditionDate, setExpeditionDate] = useState(new Date());
  const [expeditionDateErrorMessage, setExpeditionDateErrorMessage] =
    useState("");
  const [expeditionDateHint, setExpeditionDateHint] = useState("");
  const [expeditionDateLabel, setExpeditionDateLabel] = useState("");
  const [expeditionDateString, setExpeditionDateString] = useState(
    getStringFromDate(new Date())
  );
  const [expeditionDateConstraint, setExpeditionDateConstraint] = useState();

  const [expirationDate, setExpirationDate] = useState(new Date());
  const [expirationDateErrorMessage, setExpirationDateErrorMessage] =
    useState("");
  const [expirationDateHint, setExpirationDateHint] = useState("");
  const [expirationDateLabel, setExpirationDateLabel] = useState("");
  const [expirationDateString, setExpirationDateString] = useState("");

  const [loadedData, setLoadedData] = useState(false);
  const [changedData, setChangedData] = useState(false);
  const [canChangeData, setCanChangeData] = useState(false);

  const storageManager = new StorageManager();

  function validateNombres(value) {
    if (!value) {
      setNombresErrorMessage("Ingresa tu nombre");
      return true;
    }

    setNombresErrorMessage("");
    return true;
  }

  function setUpNombres(value) {
    switch (country) {
      case "COL":
        setNombresLabel("Nombres");
        break;
      case "CR":
        setNombresLabel("Nombre");
        break;
      case "NI":
        setNombresLabel("Nombres");
        break;
      default:
        break;
    }
    if (!value) {
      setNombresErrorMessage("Ingresa tu nombre");
      return false;
    }

    setNombres(value);
    return true;
  }

  function validateApellidos(value) {
    if (!value) {
      setApellidosErrorMessage("Ingresa tus apellidos");
      return true;
    }

    setApellidosErrorMessage("");
    return true;
  }

  function setUpApellidos(value) {
    switch (country) {
      case "COL":
        setApellidosLabel("Apellidos");
        break;
      case "CR":
        setApellidosLabel("Apellidos");
        break;
      case "NI":
        setApellidosLabel("Apellidos");
        break;
      default:
        break;
    }
    if (!value) {
      setApellidosErrorMessage("Ingresa tus apellidos");
      return false;
    }

    setApellidos(value);
    return true;
  }

  function documentNumberValidate(value) {
    if (!value) {
      setDocumentNumberErrorMessage("Ingresa tu número de documento");
      return true;
    } else if (value) {
      let onlyDigitsReg = /^\d+$/;
      let onlyDigitsRequired = false;
      let numDigits = 10;
      let minDigits = 6;

      if (documentType === "PASAPORTE") {
        onlyDigitsRequired = false;
        numDigits = 9;
        minDigits = 6;
      }

      switch (country) {
        case "COL":
          if (documentType === "CEDULA") {
            onlyDigitsRequired = true;
            numDigits = 10;
            minDigits = 6;
          }
          break;
        case "CR":
          if (documentType === "CEDULA") {
            onlyDigitsRequired = true;
            numDigits = 9;
            minDigits = 8;
          }
          break;
        case "NI":
          if (documentType === "CEDULA") {
            onlyDigitsRequired = false;
            numDigits = 14;
            minDigits = 6;
          }
          break;
        case "PE":
          if (documentType === "CEDULA") {
            onlyDigitsRequired = true;
            numDigits = 9;
            minDigits = 8;
          }
          break;
        default:
          onlyDigitsRequired = false;
          numDigits = 10;
          minDigits = 6;
      }
      if (onlyDigitsRequired) {
        if (!onlyDigitsReg.test(value)) {
          return false;
        }
      }
      if (value.length > numDigits) {
        return false;
      }

      if (value.length < minDigits) {
        setDocumentNumberErrorMessage(
          `El documento debe tener minimo ${minDigits} digitos`
        );
        return true;
      }
    }

    setDocumentNumberErrorMessage("");
    return true;
  }

  function setUpDocumentNumber(value) {
    let onlyDigitsReg = /^\d+$/;
    let onlyDigitsRequired = false;
    let numDigits = 10;
    let minDigits = 0;
    switch (country) {
      case "COL":
        if (documentType === "CEDULA") {
          onlyDigitsRequired = true;
          numDigits = 10;
          minDigits = 6;
          setDocumentNumberKeyboard("number");
        }
        setDocumentNumberLabel("No. de documento");
        setDocumentNumberHint("Sin puntos Ej: 80568547");

        break;
      case "CR":
        if (documentType === "CEDULA") {
          onlyDigitsRequired = true;
          numDigits = 9;
          minDigits = 8;
          setDocumentNumberKeyboard("number");
        }
        setDocumentNumberHint("Sin espacios Ej: 603300864");
        setDocumentNumberLabel("No. de documento");

        break;
      case "NI":
        if (documentType === "CEDULA") {
          onlyDigitsRequired = false;
          numDigits = 14;
          minDigits = 14;
          setDocumentNumberKeyboard("text");
        }
        setDocumentNumberHint("Sin guiones Ej: 0012909990000A");
        setDocumentNumberLabel("No. Cedula");

        break;
      case "PE":
        if (documentType === "CEDULA") {
          onlyDigitsRequired = true;
          numDigits = 9;
          minDigits = 8;
          setDocumentNumberKeyboard("number");
        }
        setDocumentNumberHint("Sin guión Ej: 434008267");
        setDocumentNumberLabel("No. de documento");

        break;
      default:
        onlyDigitsRequired = false;
        numDigits = 10;
        minDigits = 0;
    }

    if (documentType === "PASAPORTE") {
      onlyDigitsRequired = false;
      numDigits = 9;
      minDigits = 6;
      setDocumentNumberKeyboard("text");
    }
    if (!value) {
      setDocumentNumberErrorMessage("Ingresa tu número de documento");
      return false;
    } else if (value) {
      value = value.toUpperCase();
      if (onlyDigitsRequired) {
        if (!onlyDigitsReg.test(value)) {
          setDocumentNumberErrorMessage(`Ingrese un documento valido`);
          setDocumentNumber("");
          return false;
        }
        if (value.length > numDigits) {
          setDocumentNumber(value.slice(0, numDigits));
          setDocumentNumberErrorMessage(
            `El documento debe tener maximo ${numDigits} digitos`
          );
          return false;
        }
        if (value.length < minDigits) {
          setDocumentNumberErrorMessage(
            `El documento debe tener minimo ${minDigits} digitos`
          );
          setDocumentNumber(value);
          return false;
        }
      }
    }

    setDocumentNumber(value);
    return true;
  }

  function expeditionCityValidate(value) {
    if (documentType !== "PASAPORTE") {
      let emptyErrorMessage = "Ingresa la ciudad de expedición";
      switch (country) {
        case "COL":
          emptyErrorMessage = "Ingresa la ciudad de expedición";
          break;
        default:
          break;
      }

      if (!value) {
        setExpeditionCityErrorMessage(emptyErrorMessage);
        return true;
      }

      setExpeditionCityErrorMessage("");
      return true;
    } else {
      return true;
    }
  }

  function setupExpeditionCity(value) {
    if (documentType !== "PASAPORTE") {
      let emptyErrorMessage = "Ingresa el lugar de expedición";
      switch (country) {
        case "COL":
          emptyErrorMessage = "Ingresa el lugar de expedición";
          setExpeditionCityHint(
            "Tal como aparece en el documento. Ej: Bogotá D.C."
          );
          setExpeditionCityLabel("Lugar de expedición");
          break;
        default:
          return true;
      }

      if (!value) {
        setExpeditionCityErrorMessage(emptyErrorMessage);
        return false;
      }

      setExpeditionCity(value);

      return true;
    } else {
      return true;
    }
  }

  function birthPlaceValidate(value) {
    let emptyErrorMessage = "Ingresa el lugar de nacimiento";
    switch (country) {
      case "COL":
        emptyErrorMessage = "Ingresa la ciudad de nacimiento";
        break;
      case "CR":
        emptyErrorMessage = "Ingresa el lugar de nacimiento";
        break;
      case "NI":
        emptyErrorMessage = "Ingresa el lugar de nacimiento";
        break;
      default:
        break;
    }
    if (documentType === "PASAPORTE") {
      emptyErrorMessage = "Ingresa el lugar de nacimiento";
    }

    if (!value) {
      setBirthPlaceErrorMessage(emptyErrorMessage);
      return true;
    }

    setBirthPlaceErrorMessage("");
    return true;
  }

  function setupBirthPlace(value) {
    let emptyErrorMessage = "Ingresa el lugar de nacimiento";
    switch (country) {
      case "COL":
        emptyErrorMessage = "Ingresa la ciudad de nacimiento";
        setBirthPlaceHint("Tal como aparece en el documento. Ej: Bucaramanga");
        setBirthPlaceLabel("Ciudad de nacimiento");
        break;
      case "CR":
        emptyErrorMessage = "Ingresa el lugar de nacimiento";
        setBirthPlaceHint("Tal como aparece en el documento. Ej: San José");
        setBirthPlaceLabel("Lugar de nacimiento");
        break;
      case "NI":
        emptyErrorMessage = "Ingresa el lugar de nacimiento";
        setBirthPlaceHint("Tal como aparece en el documento. Ej: Managua");
        setBirthPlaceLabel("Lugar de nacimiento");
        break;
      default:
        return true;
    }
    if (documentType === "PASAPORTE") {
      emptyErrorMessage = "Ingresa el lugar de nacimiento";
      setBirthPlaceLabel("Lugar de nacimiento");
      setBirthPlaceHint("Tal como aparece en el documento. Ej: BOGOTA COL");
    }

    if (!value) {
      setBirthPlaceErrorMessage(emptyErrorMessage);
      return false;
    }

    setBirthPlace(value);
    return true;
  }

  function birthStateValidate(value) {
    if (documentType !== "PASAPORTE") {
      let emptyErrorMessage = "Ingresa el departamento de nacimiento";
      switch (country) {
        case "COL":
          emptyErrorMessage = "Ingresa el departamento de nacimiento";
          break;

        default:
          break;
      }

      if (!value) {
        setBirthStateErrorMessage(emptyErrorMessage);
        return true;
      }

      setBirthStateErrorMessage("");
      return true;
    } else {
      return true;
    }
  }

  function setupBirthState(value) {
    if (documentType !== "PASAPORTE") {
      let emptyErrorMessage = "Ingresa el departamento de nacimiento";
      switch (country) {
        case "COL":
          emptyErrorMessage = "Ingresa el departamento de nacimiento";
          setBirthStateHint("Tal como aparece en el documento. Ej: Tolima");
          setBirthStateLabel("Departamento de nacimiento");
          break;

        default:
          return true;
      }

      if (!value) {
        setBirthStateErrorMessage(emptyErrorMessage);
        return false;
      }

      setBirthState(value);
      return true;
    } else {
      return true;
    }
  }

  function validateBloodType(value) {
    if (documentType !== "PASAPORTE") {
      if (!value) {
        setBloodTypeErrorMessage("Ingresa el tipo de sangre");
        return true;
      } else if (
        value.length < 3 &&
        !value.includes("-") &&
        !value.includes("+")
      ) {
        setBloodTypeErrorMessage("Ingresa el RH (+ o -)");
        return true;
      } else {
        // validate blood type and rh
        const regex = /(A|B|AB|O)[+-]/;
        if (!regex.test(value.toUpperCase())) {
          return false;
        }
      }

      setBloodTypeErrorMessage("");
      return true;
    } else {
      return true;
    }
  }

  function setupBloodType(value) {
    if (documentType !== "PASAPORTE") {
      switch (country) {
        case "COL":
          setBloodTypeLabel("Tipo de sangre");
          setBloodTypeHint("Tal como aparece en el documento. Ej: O+");
          break;
        case "CR":
          return true;
        case "NI":
          return true;
        default:
          return true;
      }
      if (!value) {
        setBloodTypeErrorMessage("Ingresa el tipo de sangre");
        return false;
      } else if (value.length > 3) {
        setBloodTypeErrorMessage("Ingresa el tipo de sangre");
        setBloodType("");
        return false;
      } else if (
        value.length < 3 &&
        !value.includes("-") &&
        !value.includes("+")
      ) {
        setBloodTypeErrorMessage("Ingresa el RH (+ o -)");
        return false;
      } else {
        // validate blood type and rh
        const regex = /(A|B|AB|O)[+-]/;
        if (!regex.test(value.toUpperCase())) {
          setBloodTypeErrorMessage("Ingresa el tipo de sangre");
          setBloodType("");
          return false;
        }
      }
      value = value.toUpperCase();
      setBloodType(value);
      return true;
    } else {
      return true;
    }
  }

  function validateSexo(value) {
    let validGenders = ["M", "F"];
    let emptyErrorMessage = "Ingresa tu sexo";
    switch (country) {
      case "COL":
        validGenders = ["M", "F", "NB", "T"];
        emptyErrorMessage = "Ingresa tu sexo";
        break;
      case "CR":
        validGenders = ["M", "F", ""];
        emptyErrorMessage = "";
        break;
      case "NI":
        validGenders = ["M", "F"];
        emptyErrorMessage = "Ingresa tu sexo";
        break;
      default:
        emptyErrorMessage = "Ingresa tu sexo";
        break;
    }

    if (documentType === "PASAPORTE") {
      validGenders = ["M", "F", "X"];
      emptyErrorMessage = "Ingresa tu sexo";
    }

    if (!value) {
      setSexoErrorMessage(emptyErrorMessage);
      return true;
    } else if (!validGenders.includes(value.toUpperCase())) {
      if (country === "COL" && value.toUpperCase() === "N") {
        setSexoErrorMessage("");
        return true;
      }
      return false;
    }

    setSexoErrorMessage("");
    return true;
  }

  function setupSexo(value) {
    let validGenders = ["M", "F"];
    let emptyErrorMessage = "Ingresa tu sexo";
    switch (country) {
      case "COL":
        validGenders = ["M", "F", "NB", "T"];
        emptyErrorMessage = "Ingresa tu sexo";
        setSexoLabel("Sexo");
        setSexoHint("Tal como aparece en el documento. Ej: M");
        break;
      case "CR":
        emptyErrorMessage = "";
        setSexoLabel("");
        setSexoHint("");
        break;
      case "NI":
        validGenders = ["M", "F"];
        emptyErrorMessage = "Ingresa tu sexo";
        setSexoLabel("Sexo");
        setSexoHint("Tal como aparece en el documento. Ej: M");
        break;
      default:
        return true;
    }

    if (documentType === "PASAPORTE") {
      validGenders = ["M", "F", "X"];
      emptyErrorMessage = "Ingresa tu sexo";
      setSexoLabel("Sexo");
      setSexoHint("Tal como aparece en el documento. Ej: M");
    }

    if (!value && country !== "CR") {
      setSexoErrorMessage(emptyErrorMessage);
      return false;
    } else if (!value && country === "CR") {
      return true;
    } else if (!validGenders.includes(value.toUpperCase())) {
      setSexoErrorMessage("Ingresa tu sexo");
      setSexo("");
      return false;
    }

    value = value.toUpperCase();

    setSexo(value);
    return true;
  }

  function validateBirthDate(value) {
    if (documentType === "PASAPORTE") {
      if (!value) {
        setBirthDateErrorMessage("Ingresa tu fecha de nacimiento");
        return true;
      } else if (value.length < 10) {
        setBirthDateErrorMessage(
          "La fecha de nacimiento debe tener minimo 10 caracteres"
        );
        return true;
      } else if (value.length > 15) {
        return false;
      }
    } else {
      if (!value) {
        setBirthDateErrorMessage("Ingresa tu fecha de nacimiento");
        return true;
      }
    }

    setBirthDateErrorMessage("");
    return true;
  }

  function setupBirthDate(value) {
    switch (country) {
      case "COL":
        setBirthDateLabel("Fecha de nacimiento");
        break;
      case "CR":
        setBirthDateLabel("Fecha de nacimiento");
        break;
      case "NI":
        setBirthDateLabel("Fecha de nacimiento");
        break;
      default:
        return true;
    }

    if (documentType === "PASAPORTE") {
      setBirthDateLabel("Fecha de nacimiento");
      setBirthDateHint("Tal como aparece en el documento. Ej: 20 NOV/NOV 1990");
    }

    if (documentType === "PASAPORTE") {
      if (!value) {
        setBirthDateErrorMessage("Ingresa tu fecha de nacimiento");
        return false;
      } else if (value.length < 10) {
        setBirthDateErrorMessage(
          "La fecha de nacimiento debe tener minimo 10 caracteres"
        );
        setBirthDateString(value);
        return false;
      } else if (value.length > 15) {
        return false;
      } else {
        setBirthDateString(value.toUpperCase());
        return true;
      }
    } else {
      if (value && isValidDate(value.toUpperCase())) {
        setBirthDate(getDateFromString(value.toUpperCase()));
        setBirthDateString(value.toUpperCase());
        return true;
      } else {
        setBirthDateErrorMessage("Ingresa una fecha de nacimiento válida");
        setBirthDateString("");
        setBirthDate(getDateFromString(""));
        return false;
      }
    }
  }

  function validateExpeditionDate(value) {
    if (documentType === "PASAPORTE") {
      if (!value) {
        setExpeditionDateErrorMessage("Ingresa la fecha de expedición");
        return true;
      } else if (value.length < 10) {
        setExpeditionDateErrorMessage(
          "La fecha de expedición debe tener minimo 10 caracteres"
        );
        return true;
      } else if (value.length > 15) {
        return false;
      }
    } else {
      if (!value) {
        setExpeditionDateErrorMessage("Ingresa la fecha de expedición");
        return true;
      }
    }

    setExpeditionDateErrorMessage("");
    return true;
  }

  function setupExpeditionDate(value) {
    switch (country) {
      case "COL":
        setExpeditionDateLabel("Fecha de expedición");
        setExpeditionDateConstraint({
          type: "MINDATE",
          unit: "YEARS",
          constraint: 18,
          comparedTo: "BIRTHDATE",
          message: "Debes ser mayor de edad",
        });
        break;
      case "CR":
        return true;
      case "NI":
        setExpeditionDateLabel("Fecha de emisión");
        break;
      case "PE":
        setExpeditionDateLabel("Fecha de emisión");
        break;
      default:
        return true;
    }

    if (documentType === "PASAPORTE") {
      setExpeditionDateLabel("Fecha de expedición");
      setExpeditionDateHint(
        "Tal como aparece en el documento. Ej: 20 NOV/NOV 1990"
      );
    }

    if (documentType === "PASAPORTE") {
      if (!value) {
        setExpeditionDateErrorMessage("Ingresa la fecha de expedición");
        return false;
      } else if (value.length < 10) {
        setExpeditionDateErrorMessage(
          "La fecha de expedición debe tener minimo 10 caracteres"
        );
        setExpeditionDateString(value.toUpperCase());
        return false;
      } else if (value.length > 15) {
        return false;
      } else {
        setExpeditionDateString(value.toUpperCase());
        return true;
      }
    } else {
      if (value && isValidDate(value.toUpperCase())) {
        setExpeditionDate(getDateFromString(value.toUpperCase()));
        setExpeditionDateString(value.toUpperCase());
        return true;
      } else {
        setExpeditionDateErrorMessage(
          "Selecciona una fecha de expedición válida"
        );
        setExpeditionDateString("");
        setExpeditionDate(getDateFromString(""));
        return false;
      }
    }
  }

  function getExpeditionDateMinDate() {
    if (expeditionDateConstraint) {
      if (expeditionDateConstraint.comparedTo === "BIRTHDATE") {
        let baseDate = birthDate;
        if (baseDate) {
          switch (expeditionDateConstraint.unit) {
            case "YEARS":
              baseDate = addYears(
                baseDate,
                expeditionDateConstraint.constraint
              );
              console.log("After", baseDate);
              break;
            case "MONTHS":
              baseDate = addMonths(
                baseDate,
                expeditionDateConstraint.constraint
              );
              break;
            case "DAYS":
              baseDate = addDays(baseDate, expeditionDateConstraint.constraint);
              break;
            default:
              break;
          }
          return baseDate;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  function validateExpirationDate(value) {
    if (documentType === "PASAPORTE") {
      if (!value) {
        setExpirationDateErrorMessage("Ingresa la fecha de vencimiento");
        return true;
      } else if (value.length < 10) {
        setExpirationDateErrorMessage(
          "La fecha de vencimiento debe tener minimo 10 caracteres"
        );
        return true;
      } else if (value.length > 15) {
        return false;
      }
    } else {
      if (!value) {
        setExpirationDateErrorMessage("Ingresa la fecha de vencimiento");
        return true;
      }
    }

    setExpirationDateErrorMessage("");
    return true;
  }

  function setupExpirationDate(value) {
    switch (country) {
      case "COL":
        if (documentType !== "PASAPORTE") {
          return true;
        }
        break;
      case "CR":
        setExpirationDateLabel("Fecha de vencimiento");
        break;
      case "NI":
        setExpirationDateLabel("Fecha de expiración");
        break;
      case "PE":
        setExpirationDateLabel("Fecha de caducidad");
        break;
      default:
        return true;
    }

    if (documentType === "PASAPORTE") {
      setExpirationDateLabel("Fecha de vencimiento");
      setExpirationDateHint(
        "Tal como aparece en el documento. Ej: 20 NOV/NOV 1990"
      );
    }

    if (documentType === "PASAPORTE") {
      if (!value) {
        setExpirationDateErrorMessage("Ingresa la fecha de vencimiento");
        return false;
      } else if (value.length < 10) {
        setExpirationDateErrorMessage(
          "La fecha de vencimiento debe tener minimo 10 caracteres"
        );
        setExpirationDateString(value.toUpperCase());
        return false;
      } else if (value.length > 15) {
        return false;
      } else {
        setExpirationDateString(value.toUpperCase());
        return true;
      }
    } else {
      if (value && isValidDate(value.toUpperCase())) {
        setExpirationDate(getDateFromString(value.toUpperCase()));
        setExpirationDateString(value.toUpperCase());
        return true;
      } else {
        setExpirationDateErrorMessage(
          "Selecciona una fecha de vencimiento válida"
        );
        setExpirationDateString("");
        setExpirationDate(getDateFromString(""));
        return false;
      }
    }
  }

  function buttonDisabled() {
    let disabled = false;
    if (nombresLabel && nombresErrorMessage) {
      disabled = true;
    }
    if (apellidosLabel && apellidosErrorMessage) {
      disabled = true;
    }
    if (documentNumberLabel && documentNumberErrorMessage) {
      disabled = true;
    }
    if (expeditionDateLabel && expeditionDateErrorMessage) {
      disabled = true;
    }
    if (expeditionCityLabel && expeditionCityErrorMessage) {
      disabled = true;
    }
    if (birthDateLabel && birthDateErrorMessage) {
      disabled = true;
    }
    if (sexoLabel && sexoErrorMessage) {
      disabled = true;
    }
    if (birthPlaceLabel && birthPlaceErrorMessage) {
      disabled = true;
    }
    if (bloodTypeLabel && bloodTypeErrorMessage) {
      disabled = true;
    }
    if (birthStateLabel && birthStateErrorMessage) {
      disabled = true;
    }
    if (expirationDateLabel && expirationDateErrorMessage) {
      disabled = true;
    }
    return disabled;
  }

  function gotoAgent() {
    history.push(`/agentFinal?code=${UrlCode}`);
  }

  async function finishFlow() {
    setLoadedData(false);

    if (changedData) {
      if (documentType === "CEDULA") {
        let data = await api.confirmData({
          firstName: nombres.toUpperCase(),
          lastName: apellidos.toUpperCase(),
          documentNumber: documentNumber,
          expeditionDate: expeditionDateString.toUpperCase(),
          expeditionCity: expeditionCity.toUpperCase(),
          birthDate: birthDateString.toUpperCase(),
          gender: sexo.toUpperCase(),
          birthPlace: birthPlace.toUpperCase(),
          bloodType: bloodType.toUpperCase(),
          birthState: birthState.toUpperCase(),
        });

        if (data) {
          await api.final(gotoClose, gotoAgent);
        }
      } else {
        let data = await api.confirmData({
          firstName: nombres.toUpperCase(),
          lastName: apellidos.toUpperCase(),
          documentNumber: documentNumber.toUpperCase(),
          expeditionDate: expeditionDateString.toUpperCase(),
          expirationDate: expirationDateString.toUpperCase(),
          birthDate: birthDateString.toUpperCase(),
          birthPlace: birthPlace.toUpperCase(),
          gender: sexo.toUpperCase(),
        });
        if (data) {
          await api.final(gotoClose, gotoAgent);
        }
      }
    } else {
      await api.final(gotoClose, gotoAgent);
    }
  }

  async function notCorrect() {
    setLoadedData(false);
    let project = storageManager.getProject();

    let currentTries = storageManager.getGenericString("confirmDataTries");
    // convert to int
    currentTries = parseInt(currentTries);
    if (!currentTries) {
      currentTries = 0;
    }
    if (project.extraConfig.confirmDataTries) {
      if (currentTries < project.extraConfig.confirmDataTries) {
        currentTries++;
        storageManager.setGenericString(
          currentTries.toString(),
          "confirmDataTries"
        );
        history.push(`/biometrics?code=${UrlCode}&confirmData=true`);
      } else {
        setCanChangeData(true);
      }
    } else {
      setCanChangeData(true);
    }
    setLoadedData(true);
  }

  function isValidDatePassport(dateString) {
    const months = {
      ENE: 0,
      FEB: 1,
      MAR: 2,
      ABR: 3,
      MAY: 4,
      JUN: 5,
      JUL: 6,
      AGO: 7,
      SEP: 8,
      OCT: 9,
      NOV: 10,
      DIC: 11,
    };

    // Regular expression pattern for the expected format
    const pattern = /^(\d{1,2})\s([A-Z]{3})\/\2\s(\d{4})$/;

    // Check if the string matches the pattern
    const match = dateString.match(pattern);

    if (match) {
      const day = parseInt(match[1]);
      const monthAbbr = match[2];
      const monthIndex = months[monthAbbr];
      const year = parseInt(match[3]);

      // Validate the day, month, and year values
      if (
        day >= 1 &&
        day <= 31 &&
        monthIndex !== undefined &&
        year >= 1000 &&
        year <= 9999
      ) {
        return true;
      }
    }

    return false;
  }

  function isValidDate(dateString) {
    if (dateString === null || dateString === undefined || dateString === "") {
      return false;
    }
    if (
      documentType === "PASSPORT" &&
      (country === "COL" || country === "CR")
    ) {
      return isValidDatePassport(dateString);
    }

    let dateSeparator = "";
    let usesShortMonth = false;

    switch (country) {
      case "COL":
        dateSeparator = "-";
        usesShortMonth = true;
        break;
      case "CR":
        dateSeparator = " ";
        usesShortMonth = false;
        break;
      case "NI":
        dateSeparator = "-";
        usesShortMonth = false;
        break;
      default:
        dateSeparator = "/";
        usesShortMonth = false;
        break;
    }

    const monthAbbreviations = {
      ENE: 1,
      FEB: 2,
      MAR: 3,
      ABR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AGO: 8,
      SEP: 9,
      SEPT: 9,
      OCT: 10,
      NOV: 11,
      DIC: 12,
    };

    let [day, month, year] = dateString.split(dateSeparator);
    if (usesShortMonth) {
      month = monthAbbreviations[month];
    }
    const formattedDate = new Date(year, month - 1, day);
    if (formattedDate.toString() === "Invalid Date") return false;

    return true;
  }

  function getDateFromString(dateString) {
    if (dateString === null || dateString === undefined || dateString === "") {
      return null;
    }

    let dateSeparator = "";
    let usesShortMonth = false;

    switch (country) {
      case "COL":
        dateSeparator = "-";
        usesShortMonth = true;
        break;
      case "CR":
        dateSeparator = " ";
        usesShortMonth = false;
        break;
      case "NI":
        dateSeparator = "-";
        usesShortMonth = false;
        break;
      default:
        dateSeparator = "/";
        usesShortMonth = false;
        break;
    }
    const monthAbbreviations = {
      ENE: 1,
      FEB: 2,
      MAR: 3,
      ABR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AGO: 8,
      SEP: 9,
      SEPT: 9,
      OCT: 10,
      NOV: 11,
      DIC: 12,
    };

    let [day, month, year] = dateString.split(dateSeparator);

    if (usesShortMonth) {
      month = monthAbbreviations[month];
    }

    const formattedDate = new Date(year, month - 1, day);
    if (formattedDate.toString() === "Invalid Date") return null;
    return formattedDate;
  }

  function getStringFromDate(date) {
    if (date === null || date === undefined) return "";

    const months = {
      0: "ENE",
      1: "FEB",
      2: "MAR",
      3: "ABR",
      4: "MAY",
      5: "JUN",
      6: "JUL",
      7: "AGO",
      8: "SEP",
      9: "OCT",
      10: "NOV",
      11: "DIC",
    };

    let options = undefined;
    let needsReplacement = false;
    let needsUpperCase = false;
    let replacementChar = "";

    // Config for colombian passport
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    // convert date to format 26/ NOV/NOV 2023
    switch (country) {
      case "COL":
        if (documentType === "PASAPORTE") {
          return `${day} ${months[monthIndex]}/${months[monthIndex]} ${year}`;
        } else {
          options = {
            day: "2-digit",
            month: "short",
            year: "numeric",
          };
          needsReplacement = true;
          replacementChar = "-";
          needsUpperCase = false;
        }
        break;
      case "CR":
        options = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        };
        needsReplacement = true;
        replacementChar = " ";
        needsUpperCase = false;
        break;
      case "NI":
        options = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        };
        needsReplacement = true;
        replacementChar = "-";
        needsUpperCase = false;
        break;
      default:
        options = {
          day: "2-digit",
          month: "short",
          year: "numeric",
        };
        needsReplacement = false;
        needsUpperCase = true;
        break;
    }

    let formattedDate = date.toLocaleDateString("es-ES", options);
    if (needsReplacement) {
      if (options.month === "2-digit") {
        formattedDate = formattedDate.replace(/\//g, replacementChar);
        //replaces the dots with empty
        formattedDate = formattedDate.replace(/\./g, "");
      } else {
        formattedDate = formattedDate.replace(/ /g, replacementChar);
      }
    }
    if (needsUpperCase) {
      formattedDate = formattedDate.toUpperCase();
    } else {
      formattedDate = formattedDate.toLowerCase();
    }

    return formattedDate;
  }

  async function setUpData() {
    let dataIsWrong = false;
    let nombresCorrect = setUpNombres(documentData.firstName);
    let apellidosCorrect = setUpApellidos(documentData.lastName);
    let documentNumberCorrect = setUpDocumentNumber(
      documentData.documentNumber
    );
    let expeditionDateCorrect = setupExpeditionDate(
      documentData.expeditionDate
    );

    let expeditionCityCorrect = setupExpeditionCity(
      documentData.expeditionCity
    );
    let expirationDateCorrect = setupExpirationDate(
      documentData.expirationDate
    );
    let birthDateCorrect = setupBirthDate(documentData.birthDate);
    let birthPlaceCorrect = setupBirthPlace(documentData.birthPlace);
    let birthStateCorrect = setupBirthState(documentData.birthState);
    let bloodTypeCorrect = setupBloodType(documentData.bloodType);
    let sexoCorrect = setupSexo(documentData.gender);

    dataIsWrong =
      !nombresCorrect ||
      !apellidosCorrect ||
      !documentNumberCorrect ||
      !expeditionDateCorrect ||
      !expeditionCityCorrect ||
      !expirationDateCorrect ||
      !birthDateCorrect ||
      !birthPlaceCorrect ||
      !birthStateCorrect ||
      !bloodTypeCorrect ||
      !sexoCorrect;
    if (dataIsWrong) {
      setCanChangeData(true);
    }
    setLoadedData(true);
  }

  async function getData() {
    let currentTries = storageManager.getGenericString("confirmDataTries");
    if (currentTries) {
      await new Promise((r) => setTimeout(r, 7000));
    }
    let data = await api.getRegistrationData();
    let documentData = data.userDocument;

    if (documentData) {
      if (documentData.type) {
        setDocumentType(documentData.type);
      } else if (data.userIdType) {
        setDocumentType(data.userIdType);
      } else {
        setDocumentType("CEDULA");
      }
      setDocumentData(documentData);
    } else {
      history.push(`/biometrics?code=${UrlCode}&confirmData=true`);
    }
  }
  function gotoClose() {
    history.push("/closeWindow");
  }
  useEffect(() => {
    if (documentType && documentData) {
      setUpData();
    }
  }, [documentType, documentData]);
  useEffect(() => {
    let project = storageManager.getProject();
    if (project) {
      if (!project.extraConfig.canConfirm) {
        //finish flow
        api.final(gotoClose, gotoAgent);
      }
      if (
        project.extraConfig &&
        project.extraConfig.confirmDataDocument !== undefined
      ) {
        setDocumentNumberCanEdit(project.extraConfig.confirmDataDocument);
      }
    }
    if (country) {
      getData();
    } else {
      let countryProject = project.country;
      console.log("countryProject", countryProject);
      if (!countryProject) {
        setCountry("COL");
      } else {
        setCountry(countryProject);
      }
    }

    return () => {};
  }, [country]);

  return (
    <div
      className="flex-col align-center justify-center"
      style={{
        height: !loadedData ? "100vh" : "100%",
        width: !loadedData ? "100vw" : "100%",
        padding: "25px 0 25px 0",
        overflow: "scroll",
      }}
    >
      {!loadedData ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ClipLoader color="var(--fontColorPrimary)" size={"120px"} />{" "}
          <h6
            className="font-weight-700 uppercase font-white text-center"
            style={{ fontSize: "20px", width: "45%", margin: "22px 0 0 0" }}
          >
            Procesando información
          </h6>
        </div>
      ) : (
        <>
          {canChangeData ? (
            <>
              <h6
                className="font-weight-800 uppercase font-white text-center"
                style={{ fontSize: "20px", width: "60%", margin: "0 0 15px 0" }}
              >
                Confirma tus datos
              </h6>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "21.8px",
                  textAlign: "center",
                  width: "75%",
                  color: "var(--fontColorHint)",
                  marginBottom: "30px",
                }}
              >
                Edita los campos incorrectos para corregirlos y confirmalos
              </p>
            </>
          ) : (
            <>
              <h6
                className="font-weight-800  font-white text-center"
                style={{ fontSize: "20px", width: "60%", margin: "0 0 15px 0" }}
              >
                ¿Todos tus datos son correctos?
              </h6>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "21.8px",
                  textAlign: "center",
                  width: "75%",
                  color: "var(--fontColorHint)",
                  marginBottom: "30px",
                }}
              >
                Revisa tu documento cuidadosamente y confirma si concuerda con
                los siguientes datos
              </p>
            </>
          )}

          <>
            {nombresLabel && (
              <TextFieldNew
                disabled={!canChangeData}
                label={nombresLabel}
                type="text"
                value={nombres}
                onChange={(e) => {
                  if (validateNombres(e.target.value)) {
                    setChangedData(true);
                    setNombres(e.target.value);
                  }
                }}
                errorText={nombresErrorMessage}
              />
            )}
            {apellidosLabel && (
              <TextFieldNew
                disabled={!canChangeData}
                label={apellidosLabel}
                type="text"
                value={apellidos}
                onChange={(e) => {
                  if (validateApellidos(e.target.value)) {
                    setChangedData(true);
                    setApellidos(e.target.value);
                  }
                }}
                errorText={apellidosErrorMessage}
              />
            )}
            {documentNumberLabel && (
              <TextFieldNew
                disabled={!canChangeData || !documentNumberCanEdit}
                label={documentNumberLabel}
                type={documentNumberKeyboard}
                value={documentNumber}
                onChange={(e) => {
                  if (documentNumberValidate(e.target.value)) {
                    setChangedData(true);
                    setDocumentNumber(e.target.value);
                  }
                }}
                helpText={documentNumberHint}
                errorText={documentNumberErrorMessage}
              />
            )}
            {birthPlaceLabel && (
              <TextFieldNew
                disabled={!canChangeData}
                label={birthPlaceLabel}
                type="text"
                value={birthPlace}
                onChange={(e) => {
                  if (birthPlaceValidate(e.target.value)) {
                    setChangedData(true);
                    setBirthPlace(e.target.value);
                  }
                }}
                helpText={birthPlaceHint}
                errorText={birthPlaceErrorMessage}
              />
            )}
            {birthStateLabel && (
              <TextFieldNew
                disabled={!canChangeData}
                label={birthStateLabel}
                type="text"
                value={birthState}
                onChange={(e) => {
                  if (birthStateValidate(e.target.value)) {
                    setChangedData(true);
                    setBirthState(e.target.value);
                  }
                }}
                helpText={birthStateHint}
                errorText={birthStateErrorMessage}
              />
            )}
            {birthDateLabel && (
              <>
                {" "}
                {documentType === "CEDULA" ? (
                  <DateTextField
                    disabled={!canChangeData}
                    label={birthDateLabel}
                    selectedDate={birthDate}
                    maxDate={new Date()}
                    setSelectedDate={(date) => {
                      setBirthDate(date);
                      setBirthDateString(getStringFromDate(date));
                      setChangedData(true);
                      setBirthDateErrorMessage("");
                    }}
                    errorText={birthDateErrorMessage}
                  />
                ) : (
                  <TextFieldNew
                    disabled={!canChangeData}
                    label={birthDateLabel}
                    type="text"
                    value={birthDateString}
                    onChange={(e) => {
                      if (validateBirthDate(e.target.value)) {
                        setChangedData(true);
                        setBirthDateString(e.target.value);
                      }
                    }}
                    helpText={birthDateHint}
                    errorText={birthDateErrorMessage}
                  />
                )}
              </>
            )}

            {expeditionCityLabel && (
              <TextFieldNew
                disabled={!canChangeData}
                label={expeditionCityLabel}
                type="text"
                value={expeditionCity}
                onChange={(e) => {
                  if (expeditionCityValidate(e.target.value)) {
                    setChangedData(true);
                    setExpeditionCity(e.target.value);
                  }
                }}
                helpText={expeditionCityHint}
                errorText={expeditionCityErrorMessage}
              />
            )}
            {expeditionDateLabel && (
              <>
                {" "}
                {documentType === "CEDULA" ? (
                  <DateTextField
                    disabled={!canChangeData}
                    label={expeditionDateLabel}
                    selectedDate={expeditionDate}
                    maxDate={new Date()}
                    minDate={getExpeditionDateMinDate()}
                    setSelectedDate={(date) => {
                      setExpeditionDate(date);
                      setExpeditionDateString(getStringFromDate(date));
                      setChangedData(true);
                      setExpeditionDateErrorMessage("");
                    }}
                    errorText={expeditionDateErrorMessage}
                  />
                ) : (
                  <TextFieldNew
                    disabled={!canChangeData}
                    label={expeditionDateLabel}
                    type="text"
                    value={expeditionDateString}
                    onChange={(e) => {
                      if (validateExpeditionDate(e.target.value)) {
                        setChangedData(true);
                        setExpeditionDateString(e.target.value);
                      }
                    }}
                    helpText={expeditionDateHint}
                    errorText={expeditionDateErrorMessage}
                  />
                )}
              </>
            )}
            {expirationDateLabel && (
              <>
                {" "}
                {documentType === "CEDULA" ? (
                  <DateTextField
                    disabled={!canChangeData}
                    label={expirationDateLabel}
                    selectedDate={expirationDate}
                    setSelectedDate={(date) => {
                      setExpirationDate(date);
                      setExpirationDateString(getStringFromDate(date));
                      setChangedData(true);
                      setExpirationDateErrorMessage("");
                    }}
                    errorText={expirationDateErrorMessage}
                  />
                ) : (
                  <TextFieldNew
                    disabled={!canChangeData}
                    label={expirationDateLabel}
                    type="text"
                    value={expirationDateString}
                    onChange={(e) => {
                      if (validateExpirationDate(e.target.value)) {
                        setChangedData(true);
                        setExpirationDateString(e.target.value);
                      }
                    }}
                    helpText={expirationDateHint}
                    errorText={expirationDateErrorMessage}
                  />
                )}
              </>
            )}
            {bloodTypeLabel && (
              <TextFieldNew
                disabled={!canChangeData}
                label={bloodTypeLabel}
                type="text"
                value={bloodType}
                onChange={(e) => {
                  if (validateBloodType(e.target.value)) {
                    setChangedData(true);
                    setBloodType(e.target.value);
                  }
                }}
                helpText={bloodTypeHint}
                errorText={bloodTypeErrorMessage}
              />
            )}
            {sexoLabel && (
              <TextFieldNew
                disabled={!canChangeData}
                label={sexoLabel}
                type="text"
                value={sexo}
                onChange={(e) => {
                  if (validateSexo(e.target.value)) {
                    setChangedData(true);
                    setSexo(e.target.value);
                  }
                }}
                helpText={sexoHint}
                errorText={sexoErrorMessage}
              />
            )}
          </>

          {!canChangeData ? (
            <>
              <Button4
                height={"36px"}
                width={"146px"}
                text="SI"
                margin="0 0 12px 0"
                onClick={() => {
                  let project = storageManager.getProject();
                  let currentTries =
                    storageManager.getGenericString("confirmDataTries");
                  let confirmDataTries = project.extraConfig.confirmDataTries;
                  addEvent(
                    "CONFIRM_DATA_YES",
                    {
                      tries: currentTries,
                      maxTries: confirmDataTries,
                    },
                    {},
                    "interaction"
                  );
                  finishFlow();
                }}
              />
              <Button5
                height={"36px"}
                width={"146px"}
                text="NO"
                margin="0 0 10px 0"
                onClick={() => {
                  let project = storageManager.getProject();
                  let currentTries =
                    storageManager.getGenericString("confirmDataTries");
                  let confirmDataTries = project.extraConfig.confirmDataTries;
                  addEvent(
                    "CONFIRM_DATA_NO",
                    {
                      tries: currentTries,
                      maxTries: confirmDataTries,
                    },
                    {},
                    "interaction"
                  );
                  notCorrect();
                }}
              />
            </>
          ) : (
            <>
              <Button4
                minHeight={"30px"}
                minWidth={"146px"}
                text="CONFIRMAR DATOS"
                margin="0 0 40px 0"
                padding="9px 18px 9px 18px"
                onClick={(e) => {
                  e.preventDefault();
                  if (buttonDisabled()) {
                    infoMessage(
                      "Por favor, revisa y completa los campos correctamente"
                    );
                    return;
                  }
                  let project = storageManager.getProject();
                  let currentTries =
                    storageManager.getGenericString("confirmDataTries");
                  let confirmDataTries = project.extraConfig.confirmDataTries;
                  addEvent(
                    "CONFIRM_DATA_CONFIRM",
                    {
                      tries: currentTries,
                      maxTries: confirmDataTries,
                    },
                    {},
                    "interaction"
                  );
                  finishFlow();
                }}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
