import React, { useState, useEffect } from "react";
import iconre from "../../static/message.png";
import useQuery from "../../hooks/useQuery";
import { Link } from "react-router-dom";

import Modal from "react-modal";
import Swal from "sweetalert2";
import {
  infoMessage,
  successMessage,
  withInputAndCall,
} from "./../utils/ErrorHandler/errorHandler";
import { QRCodeSVG } from "qrcode.react";
import ClipLoader from "react-spinners/ClipLoader";
import icono2 from "../../static/LOGO NEXT NEW.png";
import "./QrFlowIntro.scss";
import {
  hintMessageBorroso,
  hintMessageReflejo,
  hintMessageRecorteAgente,
} from "../utils/ErrorHandler/hintMessages";

import {
  connectToChat,
  getToken,
  initiateSocket,
  sendMessage,
} from "../api/assistant-connection";
import IntroLogo from "../IntroLogo/IntroLogo";

const customStylesModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "30%",
    width: "40%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "3%",
  },
  overlay: {
    background: "rgba(0,0,0,0.5)",
    zIndex: 1000,
  },
};

Modal.setAppElement("#root");

function QrFlowIntro(props) {
  const api = props.api;
  const url = window.location.href.replace("qrFlow", "") + "&qr=true";
  console.log(url);
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const UrlCode = query.get("code");

  // const [loading, setLoading] = useState(true);
  const [smsSent, setSmsSent] = useState(false);
  const [showbtn, setShowbtn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [inputError, setInputError] = useState("");

  const msgWait =
    "Estamos esperando la respectiva verificación, te recomendamos no cerrar esta ventana";
  const msgSuccess = "Redirigiendo...";
  const msgNonSuccess = "Ya puedes cerrar esta ventana";
  const [text, setText] = useState("Por favor no cierres esta página");
  const [sessId, setSessId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    // contactAgent();
    createCode();
    // hintMessageBorroso(
    //   "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+AkAAP8A+7Xdu1sAAAAASUVORK5CYII=",
    //   "#047bd6",
    //   "#000e1a"
    // );
    
  }, []);

  async function contactAgent() {
    // if (this.agentCalled) return;
    // this.agentCalled = true;
    console.log("Contacting Agent");
    //get token
    let token = await getToken();
    console.log("Got Token: " + token);
    //Initiate socket
    initiateSocket(token);
    //Connect to chat
    connectToChat(
      handleAgentMsg,
      handleAgentAccept,
      false,
      "61967220ccc4491e606cab19"
    );
  }

  function handleAgentMsg(error, msg) {
    console.log("AGENT MESSAGE INCOMING");
    console.log(msg);
    console.log("AGENT MESSAGE END");
  }
  function handleAgentAccept(accepted, id) {
    console.log("Agent accepted: " + accepted);
    console.log("Session ID: " + id);
    setSessId(id);
  }

  useEffect(() => {
    // sendMessage("Hello There", sessId);
  }, [sessId]);

  async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function waitForDone() {
    var flag = true;
    var url2 = "";
    var blocked = false;
    while (flag) {
      await sleep(5000);
      var res = await api.checkDone();
      if (res.done) {
        flag = false;
        url2 = res.url;
        blocked = res.blocked;
        setLoading(false);
      }
    }
    if (blocked) {
      setText(msgNonSuccess);
      setShowbtn(true);
      infoMessage(
        "Una de las fases de verificación no fue exitosa, nos pondremos en contacto contigo."
      ).then(() => {
        infoMessage("Ya puedes cerrar esta ventana");
      });
    } else {
      successMessage("Listo! Continuemos con el registro").then(() => {
        setText(msgSuccess);
        window.location = url2;
        setShowbtn(false);
        // window.location = url;
      });
    }
  }

  async function goBack() {
    var url2 = await api.getBackUrl();
    if (url2) window.location = url2;
  }

  async function createCode() {
    console.log(api.isQr());
    try {
      setLoading(true);
      await api.createCode();
      setText(msgWait);
      setShowbtn(false);
    } catch (e) {
      // setText(msgNonSuccess);
      // setShowbtn(true);
      // setLoading(false);
      // infoMessage("No pudimos registrar tu código QR :c");
    }

    waitForDone();
  }

  async function showSendSms() {
    if (!smsSent)
      withInputAndCall(
        "Enviar SMS",
        "Escribe tu número de celular (sin indicativo, ni espacios)",
        "Enviar",
        sendSMS,
        validateInput
      );
  }

  async function sendSMS(phone) {
    try {
      var res = await api.sendLink(phone).then(() => {
        setSmsSent(true);
        successMessage("SMS Enviado!");
      });
    } catch (e) {
      Swal.showValidationMessage(`Ocurrió un error enviando el SMS`);
    }
  }

  function validateInput(phone) {
    if (!phone) return "Escribe tu número";
    if (phone === "") return "Escribe tu número";
    if (phone.includes("+")) return "No escribas el indicativo";
    if (phone.length < 8 && phone.length !== 10)
      return "Escribe un número válido";
    else return null;
  }
  return (
    <div style={{
      //  color: "black" 
       }} className="qrflow-main">
      {props.logoUrl !== "error" && props.logoUrl !== "a" && (
        <img
          src={props.logoUrl}
          style={{
            maxWidth: "25%",
            maxHeight: "20%",
            width: "auto",
            height: "auto",
          }}
          alt="Company Logo"
        />
      )}

      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={customStylesModal}
        contentLabel="Example Modal"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <h4 style={{ color: "#000", fontWeight: "600" }}>Enviar SMS</h4>
          <h6 style={{ color: "#000", fontWeight: "500" }}>
            Escribe tu número de celular (sin indicativo, ni espacios)
          </h6>
          <label style={{ width: "60%" }}>
            <input
              type="text"
              value={phoneNumber}
              className="input-sms"
              onChange={(e) => {
                let reg = /^\d+$/;
                if (e.target.value.length === 0 || reg.test(e.target.value)) {
                  let error = validateInput(e.target.value);
                  setPhoneNumber(e.target.value);
                  setInputError(error);
                }
              }}
            />
            <p
              style={{
                textAlign: "end",
                fontSize: "11px",
                fontWeight: "400",
                color: "#cc0000",
                visibility: inputError ? "visible" : "hidden",
              }}
            >
              {inputError}
            </p>
          </label>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              width: "60%",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <button
              disabled={inputError || phoneNumber.length === 0}
              className="button-primary-color"
              type="button"
              onClick={() => {
                sendSMS(phoneNumber);
                setPhoneNumber("");
                setShowModal(false);
                setInputError("");
              }}
            >
              Enviar
            </button>
            <button
              className="button-secondary-color"
              type="button"
              onClick={() => {
                setShowModal(false);
                setInputError("");
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
      <h5
        style={{
          fontWeight: "200",
          textAlign: "start",
          // color: "white",
          width: "35%",
        }}
      >
        Escanea este código QR para realizar el registro desde tu celular, en
        caso de salirte del proceso en tu celular debes escanear el código
        nuevamente
      </h5>

      <QRCodeSVG
        value={url}
        size={258}
        bgColor={"#FFFFFF"}
        includeMargin={true}
      />
      <h6
        style={{
          fontWeight: "400",
          textAlign: "start",
          // color: "white",
          width: "35%",
        }}
      >
        Si no tienes un lector de código QR puedes descargar uno desde{" "}
        <a className="href-class"
          href="https://play.google.com/store/search?q=qr%20reader&c=apps&hl=es"
          target="_"
          style={{ color: "var(--fontColorPrimary)" }}
        >
          AQUÍ
        </a>
      </h6>
      {loading && (
        <button
          style={{
            border: "none",
            background: "transparent",
            // color: "white",
            fontSize: "1.25rem",
            fontWeight: "700",
          }}
          type="button"
          onClick={() => setShowModal(true)}
        >
          <h5>
            {smsSent
              ? "¿Quieres enviar un SMS de nuevo?"
              : "¿O prefieres recibir un SMS?"}
          </h5>
        </button>
      )}
      {/* {showbtn && (
                <Link to={`/final?code=${UrlCode}`}>
                <button className="btn-continue">Continuar acá</button>
                </Link>
              )} */}

      <ClipLoader 
      // color="#fff"
       size={"50px"} />
      {text && (
        <h5 style={{ textAlign: "start", width: "35%" }}>
          {text}
        </h5>
      )}

      <button className="button-primary" type="button" onClick={() => goBack()}>
        Cancelar y volver
      </button>
    </div>
  );
}

export default QrFlowIntro;
