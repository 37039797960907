import React, { useState, useEffect } from "react";
import iconre from "../../static/message.png";
import {
  infoMessage2,
  successMessage,
} from "../utils/ErrorHandler/errorHandler";
import "./RecolectVerify.scss";
import { Container, Col } from "react-bootstrap";
import smsIcon from "./../../assets/smsIcon.png";
import { useHistory, Link } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
function RecolectVerify(props) {
  let history = useHistory();
  const query = useQuery();
  const UrlCode = query.get("code");
  async function verifyOtp() {
    const api = props.api;
    var result = await api.didOtpNormal();
    console.log(result, "thiss otp");
    if (result) {
      successMessage("¡Celular confirmado!").then(() => {
        // props.nextPage();
        history.push(`/direction?code=${UrlCode}`);
      });
    } else {
      infoMessage2("No has confirmado desde tu celular!");
    }
  }

  //Resend stuff

  const [mostrarEnviar, setMostrarEnviar] = useState(false);

  async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    props.api.sendOtpGps();
  }, []);

  useEffect(() => {
    showEnviar();
  }, []);

  async function showEnviar() {
    await sleep(5000);
    setMostrarEnviar(true);
  }

  async function resend() {
    setMostrarEnviar(false);
    props.api.sendOtpGps();
    await infoMessage2("¡SMS reenviado!");
    showEnviar();
  }

  return (
    <div className="App instruccion-geo" style={{ color: "black" }}>
      <Container className="recolect_verify">
        <Col>
          <img src={smsIcon} alt="icon" />
          <h5 className="text_1">
            CONFIRMA TU
            <br />
            CELULAR
            <br />
          </h5>

          <p className="text_2">
            Ingresa el código de seguridad que te hemos enviado <br />
            a: <strong>{props.phone}</strong>
            <br />
          </p>
          <div className="div_btns">
            {/* -----------------------BUTTONS---------------------------------*/}
            <Link to={`/phone?code=${UrlCode}`}>
              <button className="btn_number_modify">
                <h1>MODIFICAR NÚMERO</h1>
              </button>
            </Link>
            <button className="btn_number_confirm" onClick={verifyOtp}>
              <h1>CONFIRMAR NÚMERO</h1>
            </button>
            {/* -----------------------RESEND---------------------------------*/}
            <div hidden={!mostrarEnviar}>
              <p className="text_3" onClick={resend}>
                RE-ENVIAR SMS
              </p>
            </div>
          </div>
        </Col>
      </Container>
    </div>
  );
}

export default RecolectVerify;
