import React from "react";
import "./Buttons.scss";

export const Button = ({ text, onClick, width, height }) => {
  return (
    <>
      <button
        className="button"
        onClick={onClick}
        style={{ width: width, height: height }}
      >
        {text}
      </button>
    </>
  );
};

export const Button1 = ({ text, onClick, width, height }) => {
  return (
    <>
      <button
        className="button1"
        onClick={onClick}
        style={{ width: width, height: height }}
      >
        {text}
      </button>
    </>
  );
};
export const Button2 = ({ text, onClick, width, height }) => {
  return (
    <>
      <button
        className="button2"
        onClick={onClick}
        style={{ width: width, height: height }}
      >
        {text}
      </button>
    </>
  );
};
export const Button3 = ({ text, onClick, width, height }) => {
  return (
    <>
      <button
        className="button3"
        onClick={onClick}
        style={{ width: width, height: height }}
      >
        {text}
      </button>
    </>
  );
};

export const Button4 = ({
  text,
  onClick,
  width,
  height,
  margin,
  padding,
  minHeight,
  minWidth,
}) => {
  return (
    <>
      <button
        className="button4"
        onClick={onClick}
        style={{
          width: width,
          height: height,
          margin: margin,
          padding: padding,
          minHeight: minHeight,
          minWidth: minWidth,
        }}
      >
        {text}
      </button>
    </>
  );
};

export const Button5 = ({ text, onClick, width, height, margin }) => {
  return (
    <>
      <button
        className="button5"
        onClick={onClick}
        style={{ width: width, height: height, margin: margin }}
      >
        {text}
      </button>
    </>
  );
};
