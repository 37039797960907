import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
const useStyles = makeStyles({
  root: {
    display: "grid",
    justifyContent: "center",

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: "10px",
      color: "white",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      color: "white",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiInputLabel-outlined": {
      color: "white",
    },

    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white",

      fontSize: "16px",
      fontWeight: "800",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
      padding: "10.5px 4px !important",
    },
  },
  root2: {
    width: "110%",
  },
});

export const TextFieldStyle = ({
  label,
  onChange,
  value,
  type,
  disabled = false,
  defaultValue,
  InputProps
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TextField
        label={label}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        className={classes.root2}
        value={value}
        onChange={onChange}
        type={type}
        disabled={disabled}
        defaultValue={defaultValue}
        InputProps={InputProps}
      />
    </div>
  );
};