import React, { useState, useEffect } from "react";
import "./LastVoiceMsg.scss";
import sound from "./../../../assets/sound.png";
import { useHistory } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";

function LastVoiceMsg() {
  const [seconds, setSeconds] = useState(1);
  const [exit, setExit] = useState(false);

  let history = useHistory();
  const query = useQuery();
  const queryCode = query.get("code");

  useEffect(() => {
 const timer = setInterval(() => {
setSeconds(seconds <= 3 ? seconds + 1 : seconds);
      console.log('seconds sjsj')
    }, 2000);
    return () => clearInterval(timer);
  });
  // --------------CONDITIONAL-------------------------
  if (seconds == 3) {
    // setExit(true);
    history.push(`/vsms?code=${queryCode}`);
  }

  return (
    <div className="LastVoice">
     
        <>
          <p className="last-p1">! Genial !</p>

          <img src={sound} alt="icon" />

          <p className="last-p2">
            TU HUELLA BIOMÉTRICA<br></br>
            SONORA<br></br>A SIDO REGISTRADA<br></br>
            SATISFACTORIAMENTE
          </p>

          <p className="last-p3">
            Esta información se utiliza únicamente para validar tu <br></br>
            identidad y es guardada de forma segura por anteia.
          </p>
        </>

    </div>
  );
}
export default LastVoiceMsg;
