import React, { useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap";
import "./RecolectDirection.scss";
import { useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import GoogleMapReact from "google-map-react";
import { addEvent } from "../api/Events/EventsApi";
import { Button2 } from "./../utils/Buttons/Buttons";
import StorageManager from "../utils/StorageManager";


import LoadingOverlay from "react-loading-overlay";
function createMapOptions(maps) {
  return {
    styles: [
      { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.fill", stylers: [{ color: "#ffffff" }] },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
      },
    ],
  };
}

/* const defaultProps = {
  center: {
    lat: 4.363496,
    lng: -74.4543,
  },
  zoom: 11,
}; */
function RecolectDirection(props) {
  let history = useHistory();

  const query = useQuery();
  const UrlCode = query.get("code");

  // Storage and skipping
  const storageManager = new StorageManager();
  useEffect(() => {
    let project = storageManager.getProject();
    console.log(project);
    if (project) {
      if (project.hasLocalization === false) {
        if (!props.needsPassword) {
          history.push(`/vemail?code=${UrlCode}`);
        } else {
          history.push(`/password?code=${UrlCode}`);
        }
      }
    }
  }, []);

  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [api, setApi] = useState(props.api);
  const [direction, setDirection] = useState({
    direccion: "",
    ciudad: "",
    pais: "",
    latitud: 4.363496,
    longitud: -74.4543,
  });

  const updateUbication = (e) => {
    setLoading(false);
    setDirection({
      ...e,
    });
  };
  const ConfirmDirection = () => {
    addEvent("GEOREF_SCREEN_ACCEPT", {}, {}, "interaction");
    if (!props.needsPassword) {
      history.push(`/vemail?code=${UrlCode}`);
    } else {
      history.push(`/password?code=${UrlCode}`);
    }
  };

  async function getLocation() {
    try {
      let resp = await api.confirmotp(updateUbication);
      if (resp && resp.address) {
        setLoading(false);
        console.log(resp);
      } else {
        if (!props.needsPassword) {
          history.push(`/vemail?code=${UrlCode}`);
        } else {
          history.push(`/password?code=${UrlCode}`);
        }
      }
    } catch (e) {
      console.log(e);
      if (!props.needsPassword) {
        history.push(`/vemail?code=${UrlCode}`);
      } else {
        history.push(`/password?code=${UrlCode}`);
      }
    }
  }

  useEffect(() => {
    addEvent("STEP_GEOREF", {}, {}, "interaction");
    getLocation();
  }, []);

  const defaultProps = {
    center: {
      lat: direction.latitud,
      lng: direction.longitud,
    },
    zoom: 11,
  };
  if (loading) {
    return (
      <div>
        <LoadingOverlay
          active={true}
          spinner
          text="Espera un momento"
          classNamePrefix="MyLoader_spinner"
        />
      </div>
    );
  }

  return (
    <div className="App" 
    // style={{ color: "white" }}
    >
      <Container className="container_recolectDirection">
        <Col>
          <p className="text_1">
            Verifiquemos <br />
            tu ubicación <br />
          </p>
          <div>
            <h6>DIRECCIÓN</h6>
            <input
              value={direction.direccion}
              name="direccion"
              onChange={updateUbication}
            />
          </div>

          <div>
            <h6>CIUDAD</h6>
            <input
              value={direction.ciudad}
              name="ciudad"
              onChange={updateUbication}
            />
          </div>
          <div>
            <h6>PAÍS</h6>
            <input
              value={direction.pais}
              name="pais"
              onChange={updateUbication}
            />
          </div>
          <div style={{ marginTop: "4em" }}>
            <Button2
              text="SIGUIENTE"
              onClick={() => ConfirmDirection()}
              width="47vw"
              height="35px"
            />
          </div>
          {/* <button
            type="button"
            className="btn_continue"
            onClick={ConfirmDirection}
          >
            <h1>CONTINUAR</h1>
          </button> */}
        </Col>
      </Container>
      <div className="map-style">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDdT6vOLnI4C_rEgapqWGakLqUFyMEFRik",
          }}
          center={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          options={createMapOptions}
        ></GoogleMapReact>
      </div>
    </div>
  );
}

export default RecolectDirection;
