export default class StorageManager {
  setToken(token: string) {
    console.log("Storage: Setting Token: " + token);
    window.sessionStorage.setItem("sessionDescriptor", token);
  }

  getToken(): string {
    let token = window.sessionStorage.getItem("sessionDescriptor");
    console.log("Storage: Getting Token:: " + token);
    if (token === null) return "";
    else return token;
  }

  deleteToken() {
    console.log("Storage: Deleting Token...");
    window.sessionStorage.removeItem("sessionDescriptor");
  }

  setId(id: string) {
    console.log("Storage: Setting Id: " + id);
    window.sessionStorage.setItem("identificator", id);
  }

  getId(): string {
    let id = window.sessionStorage.getItem("identificator");
    console.log("Storage: Getting Id:: " + id);
    if (id === null) return "";
    else return id;
  }

  deleteId() {
    console.log("Storage: Deleting Id...");
    window.sessionStorage.removeItem("identificator");
  }

  setCountry(country: string) {
    console.log("Storage: Setting Country: " + country);
    window.sessionStorage.setItem("countrario", country);
  }

  getCountry(): string {
    let country = window.sessionStorage.getItem("countrario");
    console.log("Storage: Getting Country:: " + country);
    if (country === null) return "";
    else return country;
  }

  deleteCountry() {
    console.log("Storage: Deleting Country...");
    window.sessionStorage.removeItem("countrario");
  }

  getProject(): any | null {
    console.log("Storage: Getting project");
    let proString = window.sessionStorage.getItem("albino");
 //ask how it receive data
    if (proString === null) return null;
    let pro = JSON.parse(proString);
    return pro;
  }

  setProject(pro: any) {
    console.log("Storage: Setting Project");
    window.sessionStorage.setItem("albino", JSON.stringify(pro));
  }

  deleteProject() {
    console.log("Storage: Deleting Project...");
    window.sessionStorage.removeItem("albino");
  }

  getDocType(): any | null {
    return this.getGenericString("docType");
  }

  setDocType(docType: string) {
    this.setGenericString(docType, "docType");
  }

  deleteDocType() {
    this.deleteGeneric("docType");
  }

  getGenericString(key: string): any | null {
    console.log("Storage: Getting generic");
    return window.sessionStorage.getItem(key);
  }

  setGenericString(item: any, key: string) {
    console.log("Storage: Setting Generic");
    window.sessionStorage.setItem(key, item);
  }

  getGenericJson(key: string): any | null {
    console.log("Storage: Getting generic");
    let itemString = window.sessionStorage.getItem(key);
    if (itemString === null) return null;
    let item = JSON.parse(itemString);
    return item;
  }

  setGenericJson(item: any, key: string) {
    console.log("Storage: Setting Generic");
    window.sessionStorage.setItem(key, JSON.stringify(item));
  }

  deleteGeneric(key: string) {
    console.log("Storage: Deleting Generic...");
    window.sessionStorage.removeItem(key);
  }
}
