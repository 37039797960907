import React from "react";
import cedula from "./../../../assets/id.png";
import cedulaBack from "./../../../assets/idBack.png";
import "./Texts.scss";


export const CommandTextsTarjetaPropiedad = ({step, colorCuadrito}) => {
    console.log("texts start working licencia...");
    let txts = [
      [
        "UBICA TU TARJETA",
        "DE PROPIEDAD",
        "EN EL RECUADRO COMPLETO",
        "PRIMERO EL FRENTE",
      ],
      ["CAPTURANDO", "TARJETA"],
      ["BIEN"],
      ["AHORA EL REVERSO", "EN TODO EL RECUADRO", "COMPLETO"],
    ];
    /*   if (!isMobile()) {
      txts = [
        ["UBICA TU DOCUMENTO EN TODO EL RECUADRO", "PRIMERO EL FRENTE"],
        ["CAPTURANDO", "DOCUMENTO"],
        ["BIEN"],
        ["AHORA EL REVERSO", "EN TODO EL RECUADRO"],
      ];
    } */
    let txtRed = (
      <>
        {step == 9 ? (
          <div className="txts ">
            <h2 className="AVGARDNI txt">{txts[0][0]}</h2>
            <h2 className="AVGARDNI txt">{txts[0][1]}</h2>
            <h2 className="AVGARDNI txt">{txts[0][2]}</h2>
            <h1 className="AVGARDNI txt_resalt">{txts[0][3]}</h1>
            <img className="img_1" src={cedula} alt="cedula" />
          </div>
        ) : (
          <div className="txts">
            <img className="img_2" src={cedulaBack} alt="cedula_back" />
            <h1 className="AVGARDNI txt_resalt">{txts[3][0]}</h1>
            <h2 className="AVGARDNI txt">{txts[3][1]}</h2>
            <h2 className="AVGARDNI txt">{txts[3][2]}</h2>
          </div>
        )}
      </>
    );
    let txtYellow = (
      <div className="color2">
        <h2 className="AVGARDNI txt ">{txts[1][0]}</h2>
        <h2 className="AVGARDNI txt ">{txts[1][1]}</h2>
      </div>
    );
    let txtGreen = (
      <div className="color3">
        {" "}
        <h2 className="AVGARDNI txt ">{txts[2]}</h2>
      </div>
    );
    if (colorCuadrito === "#ffffff5f") {
      return txtRed;
    } else if (colorCuadrito === "#fff352") {
      return txtYellow;
    } else if (colorCuadrito === "#00ff44") {
      return txtGreen;
    }
  }