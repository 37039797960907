import { DefaultEventsMap } from "@socket.io/component-emitter";
import { io, Socket } from "socket.io-client";
import axios from "axios";

let socket: Socket<DefaultEventsMap, DefaultEventsMap>;
export const initiateSocket = (token: string) => {
  //Es una IP porque por alguna razon la conexión se cae con el ingress.
  // Está así mientras encuentro la solución
  // socket = io("http://34.85.157.193:80", {
  //   auth: {
  //     token,
  //   },
  //   transports: ["websocket"],
  // });
  socket = io("https://api.agente.anteia.co", {
    auth: {
      token,
    },
    transports: ["websocket"],
  });
};

export const disconnectSocket = () => {
  console.log("Disconnecting socket...");
  if (socket) socket.disconnect();
};

export const chooseToDisconnect = (reason: string) => {
  if (!socket) return true;
  socket.emit("choseToDisconnect", reason);
};

export const sendMessage = (message: string, agentId: string) => {
  console.log("Sending msg");
    console.log("agentId: " + agentId);
    console.log("msg: " + message);
  if (socket && agentId !== "") {
    
    socket.emit("privatemessage", agentId, message);
  }
};

export const connectToChat = (
  chatCallback: (error: string | null, msg: string) => void,
  agentCallback: (accepted: boolean, id: string) => void,
  agentAccepted: boolean,
  id: string
) => {
  console.log("Connecting to chat....");
  if (!socket) {
    console.log("Socket was undefined!!!!!!");
    return true;
  }
  if (!agentAccepted) {
    var things = ["id1", "id2", "id3", "id4", "id5"];
    var thing = things[Math.floor(Math.random() * things.length)];
    socket.emit("register", id);
  }
  socket.on("agentaccept", (socketId) => {
    agentCallback(true, socketId);
  });

  socket.on("privatemessage", (msg) => {
    // console.log("ON PRIVATE MSG");
    // console.log(msg);
    return chatCallback(null, msg);
  });

  socket.on("agentDisconnect", (reason) => {
    console.log(`Agent Disconnected because ${reason}`);
    agentCallback(false, "");
  });

  socket.on("disconnect", (reason) => {
    console.log(`Disconnected because ${reason}`);
    agentCallback(false, "");
  });
  console.log("Chat Connected");
};

export interface Token {
  token: string;
  expiresIn: number;
}
// En producción cambiar por secreto u otra manera segura de guardar la autenticación.
let auth = { username: "coreid", password: "yT9Wb7S6P^XsPW8V" };
export const getToken = async () => {
  let token = await axios.get<Token>("https://api.agente.anteia.co/getToken", {
    auth,
  });
  if (token.data) {
    return token.data.token;
  } else {
    return "";
  }
};
