import React, { useEffect } from "react";
import TextFinal from "../textFinal";
import "./TerminosAdvices.scss";
import { Link, useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { Button2 } from "../utils/Buttons/Buttons";
import sun2 from "./../../assets/sun2.png";
import { addEvent } from "../api/Events/EventsApi";
import bulb1 from "./../../assets/bulb1.png";
import lamp1 from "./../../assets/lamp1.png";
import StorageManager from "../utils/StorageManager";
import idImage from "./../../assets/cedula.png";
function TerminosAdvices2() {
  const query = useQuery();
  const UrlCode = query.get("code");

  // Storage and skipping
  const history = useHistory();
  const storageManager = new StorageManager();
  useEffect(() => {
    let project = storageManager.getProject();
    console.log(project);
    if (project.extraConfig) {
      if (project.extraConfig.showDocumentTips === false) {
        history.push(`/advices3?code=${UrlCode}`);
      }
    }
  }, []);
  return (
    <div
      className="advices"
      style={{
        // color: "white",
        textAlign: "center",
      }}
    >
      <div className="advices2">
        {/* <img src={idImage} className="img1" /> */}
        <div className="advices2-txt">
          <TextFinal type="h1" txt={"Ubícate en"} />
          <TextFinal type="h1" txt={"un ambiente con"} />
          <TextFinal type="h1" txt={"BUENA LUZ"} />
        </div>
        <div className="advices2-txt">
          <TextFinal type="h1" txt={"Lograrás la mejor nitidez que"} />
          <TextFinal type="h1" txt={"proporcione tu cámara al enfocar tu"} />
          <TextFinal type="h1" txt={"documento"} />
        </div>
        <div className="advices2-txt">
          <TextFinal type="h1" txt={"Asegurate que la luz"} />
          <TextFinal type="h1" txt={"NO cause reflejos o se vea"} />
          <TextFinal type="h1" txt={"borroso tu documento"} />
        </div>
      </div>
      <div style={{ marginTop: "6em" }}>
        <Link to={`/advices3?code=${UrlCode}`}>
          <Button2
            text="SIGUIENTE"
            width="177px"
            height="5vh"
            onClick={(e) => {
              addEvent("ADVICE_2", {}, {}, "interaction");
            }}
          />
        </Link>
      </div>
    </div>
  );
}
export default TerminosAdvices2;
