import React, { useState, useEffect } from "react";
import iconre from "../../static/message.png";
import newMessage from "../../assets/envelope.png";
import { ReactComponent as Envelope } from "../../assets/envelop.svg";
import "./InstruccionGeo.scss";
import { Container, Row, Col } from "react-bootstrap";
import {
  infoMessage2,
  successMessage,
} from "../utils/ErrorHandler/errorHandler";
import { useHistory, Link } from "react-router-dom";
import { Button3 } from "../utils/Buttons/Buttons";
import useQuery from "../../hooks/useQuery";
import { addEvent } from "../api/Events/EventsApi";
import StorageManager from "../utils/StorageManager";
function VerifyMailPage(props) {
  let history = useHistory();
  const [mostrarEnviar, setMostrarEnviar] = useState(false);

  const query = useQuery();
  const UrlCode = query.get("code");

  const [isInPlace, setIsInPlace] = useState(false);

  function getTime() {
    var d = new Date();
    return d.getTime();
  }

  async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  console.log(props.email, "PROPSSS EMAILLLL");

  const storageManager = new StorageManager();

  async function verifyOtp() {
    const api = props.api;
    let result = await api.didOtpAndMail();
    console.log("RESULTT>>>> : ", result);
    if (result) {
      successMessage("¡Correo confirmado!").then(() => {
        console.log("PROPS EMAIL:", props, "AND");
        // props.verifyOtp();
        // history.push(`direction/?code=${UrlCode}`);
        history.push(`vsms/?code=${UrlCode}`);

        console.log(UrlCode, "URLCODE");
      });
    } else {
      console.log("¡No has confirmado tu correo!");
      infoMessage2("¡No has confirmado tu correo!");
    }
  }

  useEffect(() => {
    let project = storageManager.getProject();
    console.log(project);
    if (project) {
      if (project.hasEmailConfirmation === false) {
        history.push(`/final?code=${UrlCode}`);
        return;
      }
      if (project.extraConfig.inplaceEmailVerification === true) {
        setIsInPlace(true);
      }
    }
    // props.api.sendEmail();
    addEvent("STEP_EMAIL", {}, {}, "interaction");
    props.api.sendEmailNew();
    showEnviar();
  }, []);

  async function waitForEmail() {
    let secondsToWait = 1000;
    let flag = await props.api.didEmail();
    let initialTime = null;
    while (!flag) {
      if (!initialTime) initialTime = getTime();
      await sleep(2000);
      flag = await props.api.didEmail();
      console.log("didEmail:");
      console.log(flag);
      let dif = getTime() - initialTime;
      console.log("time dif: " + dif);
      if (dif > secondsToWait * 1000) {
        flag = true;
      }
    }
    history.push(`/final?code=${UrlCode}`);
  }

  function gotoClose() {
    history.push("/closeWindow");
  }

  useEffect(() => {
    console.log("Is In Place useEffect");
    if (isInPlace) {
      console.log("Is In Place TRUE");
      waitForEmail();
    }
  }, [isInPlace]);

  async function showEnviar() {
    await sleep(5000);
    setMostrarEnviar(true);
  }

  async function resend() {
    addEvent("EMAIL_RESEND", {}, {}, "interaction");
    setMostrarEnviar(false);
    props.api.sendEmailNew();
    await infoMessage2("¡Correo reenviado!");
    showEnviar();
  }

  return (
    <div className="App" 
    // style={{ color: "black" }}
    >
      <Container className="container_instruccionGeo">
        <Row>
          <Col
          // style={{ color: "white" }}
          >
            <p className="text_1">Te hemos enviado un correo a</p>
            <h4 className="text_6">{props.email}</h4>
            <p className="text_1" style={{ marginTop: "0px" }}>
              Para que continues <br />
              tu proceso
            </p>
            <p className="text_4">
              {" "}
              Dentro de los siguientes 30 segundos<br></br> llegará este correo
              electrónico, si no <br></br>llega a tu cuenta, oprime <br></br>{" "}
              RE-ENVIAR CORREO
            </p>
            <div>
              <Envelope style={{ margin: "24px 0 24px 0", filter:"var(--invertPng)" }} />
              <p className="text_2">REVISA TU CORREO</p>
            </div>

            <p className="text_3">
              Una vez llegue el<br></br> correo puedes cerrar<br></br> esta
              ventana
            </p>

            <div hidden={!mostrarEnviar}>
              <div style={{ marginTop: "2em" }}>
                <Button3
                  text="RE-ENVIAR CORREO"
                  onClick={resend}
                  width="60%"
                  height="5vh"
                />
              </div>
              {/* <p className="text_5" onClick={resend}>
       RE-ENVIAR CORREO
     </p> */}
            </div>
            {props.canEdit && (
              <div style={{ marginTop: "1em" }}>
                <Link to={`email/?code=${UrlCode}`}>
                  <Button3
                    text="MODIFICAR CORREO"
                    // onClick={}
                    width="60%"
                    height="5vh"
                  />
                </Link>
              </div>
              // <Link to={`email/?code=${UrlCode}`}>
              //   <button className="btn_modify" onClick={}>
              //     <h1>MODIFICAR CORREO</h1>
              //   </button>
              // </Link>
            )}

            <p className="text_4" style={{ marginTop: "2em" }}>
              Si por algun motivo no esta el correo en<br></br> tu bandeja de
              entrada revisa la carpeta<br></br>
              de spam
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default VerifyMailPage;
