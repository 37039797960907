import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core";
import logo from "../static/a2censo-logo.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    paddingLeft: "100px",
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function MenuAppBar() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div></div>
    //   <AppBar position="fixed" style={{backgroundColor:"white", color: "black", padding:"5px", top: 0, fontFamily: "Nunito !important"}}>
    //     <Toolbar>
    //       <IconButton edge="start" className={classes.menuButton}  aria-label="menu">
    //        <img src={logo} alt="Logo ascenso" height="30px"></img>

    //       </IconButton>
    //       <div className={classes.title}></div>
    //     { matches&& <div >
    //       <Button style={{color: "rgb(186, 189, 202)", backgroundColor: "transparent", border: "none", fontFamily: "Nunito", textTransform: "capitalize" }}>¿Ya tienes una cuenta?</Button>
    //     <button type="button" class="rounded-pill" style={{color: "rgb(11, 187, 239)", backgroundColor: "transparent", border: "3px solid rgb(11, 187, 239)", padding: "6px", paddingLeft:"40px", paddingRight:"40px", fontFamily: "Nunito", textTransform: "capitalize", margin: "4px", fontWeight: "bold"}}>Iniciar Sesión</button>

    //       </div>}
    // </Toolbar>
    //   </AppBar>
  );
}
