import React from "react";
import img from "./../../../assets/verification.png";
import './../../utils/TextsVideo/TextsLiveness.scss'

//   -------------------------COMMAND TEXTS BY FACE------------------------------------------------
export const CommandTextsByliveness = ({ step }) => {
  console.log(step);
  console.log("texts start working by sonrisa  ...");
  let txtsFace = [["SONRISA", "BOCA"]];
  let txtFace = (
    <div className="txt-smile">
      <img src={img} className="icon_smile" alt="icon" />
      <h2 className="AVGARDNI txtf">{txtsFace[0][0]}</h2>
    </div>
  );
  let txtFace2 = (
    <div className="txt-smile">
      <img src={img} className="icon_smile" alt="icon" />
      <h2 className="AVGARDNI txtf">{txtsFace[0][1]}</h2>
    </div>
  );

  if (step === 2) {
    return txtFace;
  } else if (step === 3) {
    return txtFace2;
  }
};

