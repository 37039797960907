import DbConnection from './db_connection';

export var DbConnectionSingleton = (function () {
    var instance;

    function createInstance() {
        var object = new DbConnection();
        return object;
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();