import React from "react";
import "./AvisoPay.scss";
import { Row, Col } from "react-bootstrap";
import icon from "./../../assets/GAFAS.png";
import icon2 from "./../../assets/VARITA.png";
import icon3 from "./../../assets/LUZ.png";
import icon4 from "./../../assets/SOMBRERO.png";
import img from "./../../assets/verification.png";
import { Button } from "../utils/Buttons/Buttons";
import useQuery from "../../hooks/useQuery";
import { Link } from "react-router-dom";
function AvisoBioPay(props) {
  const query = useQuery();
  const UrlCode = query.get("code");
  const parameters = window.location.href.split("?")[1];
  return (
    <div className="general">
      <div className="huella-container">
        <div className="text-1">
          JUNTOS REALIZAREMOS <br></br>LA TOMA DE TU <br></br> HUELLA BIOMETRICA
        </div>
        <img src={img} alt="img" />
        <div className="div-1">
          <p>PREPAREMONOS</p>
        </div>
        <div className="div-2">
          <Row>
            <Col>
              <div className="icon-div">
                <img src={icon4} alt="icon" />
              </div>
              <p>
                NO uses <br></br>
                SOMBRERO
              </p>
            </Col>
            <Col>
              <div className="icon-div">
                <img src={icon} alt="icon" />
                <p style={{ marginTop: "0.5em" }}>
                  NO uses<br></br>
                  GAFAS
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="icon-div">
                <img src={icon2} alt="icon" />
                <p>
                  NO uses <br></br>
                  EFECTOS
                </p>
              </div>
            </Col>
            <Col>
              <div className="icon-div">
                <img src={icon3} alt="icon" />
                <p style={{ marginTop: "1em" }}>
                  ILUMINATE <br></br>Bien
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="div-3">
          <p>
            Esta información se utiliza únicamente para la verificación de la
            identidad y guardada de forma segura por anteia.
          </p>
        </div>
        <div style={{ marginTop: "1em" }}>
          <Link to={`/videofacePay?${parameters}`}>
            <Button
              text="REALIZAR PAGO"
              // onClick={}
              width="177px"
              height="5vh"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
export default AvisoBioPay;
