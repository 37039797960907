import Swal from "sweetalert2";
import "./errorHandler.scss";
import alert from "./../../../assets/alert.png";
const baseurldataserver = "https://test-api.anteia.co";

function fatalError(api, reason) {
  return Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Encontramos un error y debemos reiniciar el registro",
  }).then(() => {
    if (api) {
      api.log(reason, "Fatal error");
    }
    window.location.reload();
  });
}

function restart() {}

function commonError(reason, callback) {
  return Swal.fire({
    icon: "error",
    title: "Oops!",
    text: reason,
  }).then(() => {
    if (callback) callback();
    else window.location.reload();
  });
}

function successMessage(reason) {
  return Swal.fire({
    icon: "success",
    title: "",
    text: reason,
  });
}
// Este es un alert con el icono mas grande
function infoMessage(reason) {
  return Swal.fire({
    customClass: {
      container: "style_0",
      popup: "style_1",
      title: "style_2",
      htmlContainer: "style_2",
      confirmButton: "style_3",
    },
    confirmButtonText: "ACEPTAR",
    imageUrl: alert,
    imageWidth: 60,
    imageHeight: 60,
    // title: "Información",
    text: reason,
  });
}

function retryMessage(reason, buttonText) {
  return Swal.fire({
    customClass: {
      container: "style_0",
      popup: "style_1",
      title: "style_2",
      htmlContainer: "style_2",
      confirmButton: "style_3",
    },
    confirmButtonText: buttonText ? buttonText : "Volvamos a intentarlo",
    imageUrl: alert,
    imageWidth: 60,
    imageHeight: 60,
    // title: "Información",
    text: reason,
  });
}



// Este es un alert mas suave ya que el icono es mas chico(?)
function infoMessage2(reason) {
  return Swal.fire({
    customClass: {
      container: "style_0",
      popup: "style_1",
      title: "style_2",
      htmlContainer: "style_2",
      confirmButton: "style_3",
    },
    confirmButtonText: "ACEPTAR",
    imageUrl: alert,
    imageWidth: 40,
    imageHeight: 40,
    // title: "Información",
    text: reason,
  });
}

function withInputAndCall(title, message, confirmText, call, validator) {
  return Swal.fire({
    customClass: {
      container: "style_0",
      popup: "style_1",
      title: "style_2",
      htmlContainer: "style_2",
      confirmButton: "style_3",
      cancelButton: "style_4",
      input: "style_5",
    },
    imageUrl: alert,
    imageWidth: 40,
    imageHeight: 40,
    title: title,
    text: message,
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    cancelButtonText: "Cancelar",
    inputValidator: validator,
    showCancelButton: true,
    confirmButtonText: confirmText,
    showLoaderOnConfirm: true,
    preConfirm: (txt) => call(txt),
    allowOutsideClick: () => !Swal.isLoading(),
  });
}

function ConfirmDialog(reason) {
  Swal.fire({
    title: "Are you sure?",
    text: reason,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
    }
  });
}

export {
  fatalError,
  commonError,
  infoMessage,
  successMessage,
  withInputAndCall,
  infoMessage2,
  ConfirmDialog,
  retryMessage,
};
