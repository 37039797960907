class DataRepo {
  needsRut: boolean;
  supportedDocTypes: Array<string>;
  constructor() {
    this.needsRut = false;
    this.supportedDocTypes = [];
  }
}

export default DataRepo;
