import React from "react";
import iconoanteia from "../../assets/logo_anteia.png";
import "./IntroLogo.scss";
import { detect } from "detect-browser";
import { infoMessage } from "../utils/ErrorHandler/errorHandler";
const browser = detect();
function IntroLogo(props) {
  function getLogo() {
    if (props.logoUrl && props.logoUrl !== "") {
      return props.logoUrl;
    } else {
      return iconoanteia;
    }
  }
  // if (browser.os === "iOS") {
  //   if (browser.name != "safari") {
  //     infoMessage("TU NAVEGADOR NO ES COMPATIBLE TE SUGERIMOS USAR SAFARI");
  //   }
  // } else if (browser.os === "android") {
  //   if (
  //     browser.name != "chrome" ||
  //     browser.name != "samsung" ||
  //     browser.name != "firefox"
  //   ) {
  //     infoMessage("TU NAVEGADOR NO ES COMPATIBLE TE SUGERIMOS USAR CHROME");
  //   }
  // } else {
  //   console.log(browser.os);
  // }

  // if (browser) {
  //   console.log(browser.name);
  //   console.log(browser.version);
  //   console.log(browser.os);
  // }
  return (
    <div
      style={{
        height: "80vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h6
        style={{
          margin: "0 0 6px 0",
          textTransform: "uppercase",
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "600",
        }}
      >
        Bienvenidos a
      </h6>
      {props.logoUrl !== "a" && (
        <>
          {" "}
          {props.logoUrl && props.logoUrl !== "error" ? (
            <img
              src={props.logoUrl}
              loading="eager"
              alt="logo empresa"
              style={{
                maxWidth: "300px",
                maxHeight: "120px",
                width: "auto",
                height: "auto",
              }}
            />
          ) : (
            <img
              src={iconoanteia}
              loading="eager"
              alt="logo anteia"
              style={{
                maxWidth: "300px",
                maxHeight: "60px",
                width: "auto",
                height: "auto",
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
export default IntroLogo;
