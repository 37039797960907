import React from 'react';
import SplitText from 'react-pose-text';


const charPoses = {
  exit: { opacity: 0},
  enter: {
    opacity: 1,
    delay: ({ charIndex }) => charIndex * 60
  }
};

const charPosesLight = {
  exit: { opacity: 0},
  enter: {
    opacity: 1,
    delay: ({ charIndex }) => charIndex * 60
  }
};
const getElement=(props)=>{
    return (
         <SplitText className="splitTextCustom" initialPose="exit" pose="enter" charPoses={charPoses}>
             {props.txt}
          </SplitText>
       
      );
}
function TextFinal(props) {
    if(props.type==="h1"){
        return (
            <h1>
              {getElement(props)}
            </h1>
          );
    }else if(props.type==="h2"){
        return (
            <h2>
              {getElement(props)}
            </h2>
          );
    }else if(props.type==="h3"){
        return (
            <h3>
              {getElement(props)}
            </h3>
          );    
    }else if(props.type==="h4"){
        return (
            <h4>
              {getElement(props)}
            </h4>
          );       
    }else if(props.type==="h5"){
        return (
            <h5>
              {getElement(props)}
            </h5>
          ); 
    }
    else if(props.type==="a"){
        return (
            <a onClick={()=>props.gotoRecolectData()} href="/" style={{marginLeft: "1%", cursor: "pointer"}}>
              <b>{getElement(props)}</b>
            </a>
          ); 
    }
    return (
        <h5>
          {getElement(props)}
        </h5>
      );
  

}
export default  TextFinal;