import React from "react";
import "./RecolectDataStart2.scss";
import { TextFieldStyle } from "./../utils/TextField/TextField";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory, Link } from "react-router-dom";
import useQuery from "../../hooks/useQuery";

import { Button2 } from "./../utils/Buttons/Buttons";
function RecolectDataStart2() { 
  let history = useHistory();
  const query = useQuery();
  const UrlCode = query.get("code");


  const nextAdvices =()=> {
    history.push(`/advices?code=${UrlCode}`);

  }
  return (
    <div className="recolect">
      <div>
        <p className="txt-title">
          Es momento de<br></br> conocerte<br></br>
          un poco más
        </p>
        <p className="txt-subtitle">Ingresa los siguientes datos:</p>
      </div>
      <div style={{ marginTop: "1em", marginLeft: "1.2em" }}>
        <FormControlLabel
          value="end2"
          control={<Radio color="primary" />}
          label="PERSONA NATURAL"
        />
      </div>

      <div style={{ marginLeft: "1.2em" }}>
        <FormControlLabel
          value="end"
          control={<Radio color="primary" />}
          label="PERSONA JURÍDICA"
        />
      </div>

      <div style={{ marginTop: "1em" }}>
        <TextFieldStyle label="APELLIDO" />
      </div>
      <div style={{ marginTop: "1em" }}>
        <TextFieldStyle label="DOCUMENTO" />
      </div>
      <div style={{ marginTop: "1em" }}>
        <TextFieldStyle label="No. CELULAR " />
      </div>
      <div style={{ marginTop: "1em" }}>
        <TextFieldStyle label="CORREO" />
      </div>
<div style={{ marginTop: "2em" }}>
<Button2
        text="ACEPTAR"
       onClick={(e) => nextAdvices()}
        width="177px"
        height="5vh"
      />


</div>

    </div>
  );
}
export default RecolectDataStart2;
