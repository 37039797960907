import React, { useEffect } from "react";
// import cedula from "./../../../assets/id.png";
import cedulaBack from "./../../../assets/idBack.png";
import "./Texts.scss";
export const CommandTextsByColor = ({ step, colorCuadrito }) => {
  console.log("texts start working  by color...");
  let txts = [
    ["UBICA TU DOCUMENTO", "EN ESTE RECUADRO", "PRIMERO EL FRENTE"],

    ["AHORA EL REVERSO"],
  ];

  let txtGray = (
    <>
      {step == 5 ? (
        <div className="txts ">
          <h2 className="AVGARDNI txt_resalt">{txts[0][0]}</h2>
          <h2 className="AVGARDNI txt_resalt">{txts[0][1]}</h2>

          <h1 className="AVGARDNI txt">{txts[0][2]}</h1>
          {/* <img className="img_1" src={cedula} alt="cedula" /> */}
        </div>
      ) : (
        <div className="txts-back-cedula">
          {/* <img className="img_2" src={cedulaBack} alt="cedula_back" /> */}
          <h1 className="AVGARDNI txt_resalt">{txts[1][0]}</h1>
        </div>
      )}
    </>
  );

  if (colorCuadrito === "#ffffff6f") {
    return txtGray;
  }
};
export const CommandTextsByColorTop  = ({ step, colorCuadrito }) => {
  console.log("texts start working  by color...");

  let txts = [
    [ "PRIMERO","EL", "FRENTE"],

    ["AHORA", "EL", "REVERSO"],
  ];

  let txtRedAndYellow = (
    <>
      {step == 5 ? (
       <div className="color1-top">
       <h2 className="AVGARDNI txt " style={{ marginLeft: "8px" }}>{txts[0][0]}</h2>
       <h2 className="AVGARDNI txt " style={{ marginLeft: "8px" }}>{txts[0][1]}</h2>
       <h2 className="AVGARDNI txt " style={{ marginLeft: "8px" }}>{txts[0][2]}</h2>
     </div>
      ) : (
        <div className="color1-top">
      <h2 className="AVGARDNI txt " style={{ marginLeft: "8px" }}>{txts[1][0]}</h2>
      <h2 className="AVGARDNI txt " style={{ marginLeft: "8px" }}>{txts[1][1]}</h2>
      <h2 className="AVGARDNI txt " style={{ marginLeft: "8px" }}>{txts[1][2]}</h2>
    </div>
      )}
    </>
  );


  let txtGreen = (
    <div className="color3">
  <p></p>
     
    </div>
  );

  if (colorCuadrito === "#ffffff5f" || colorCuadrito === "#fff352") {
    return txtRedAndYellow;
  }  else if (colorCuadrito === "#00ff44") {
    return txtGreen;
  }
};

export const CommandTextsByColorDown = ({ step, colorCuadrito }) => {
  console.log("texts start working  by color...");

  let txts = [
    ["ESPERANDO", "DOCUMENTO"],
    ["CAPTURANDO", "DOCUMENTO"],
    ["DOCUMENTO", "CAPTURADO"],
  ];

  let txtRed = (
    <div className="color1">
      <h2 className="AVGARDNI txt ">{txts[0][0]}</h2>
      <h2 className="AVGARDNI txt " style={{ marginLeft: "10px" }}>
        {txts[0][1]}
      </h2>
    </div>
  );

  let txtYellow = (
    <div className="color2">
      <h2 className="AVGARDNI txt ">{txts[1][0]}</h2>
      <h2 className="AVGARDNI txt " style={{ marginLeft: "10px" }}>
        {txts[1][1]}
      </h2>
    </div>
  );
  let txtGreen = (
    <div className="color3">
      {" "}
      <h2 className="AVGARDNI txt ">{txts[2][0]}</h2>
      <h2 className="AVGARDNI txt " style={{ marginLeft: "10px" }}>
        {txts[2][1]}
      </h2>
    </div>
  );

  if (colorCuadrito === "#ffffff5f") {
    return txtRed;
  } else if (colorCuadrito === "#fff352") {
    return txtYellow;
  } else if (colorCuadrito === "#00ff44") {
    return txtGreen;
  }
};
