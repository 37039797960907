import React, { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import iconglobe from "../static/globe.gif";
import iconvison from "../static/vision.png";
import iconface from "./../assets/diafragmaFace.png";
import './../sass/RecolectBiometrics.scss'
import { info } from "./utils/ErrorHandler/errorHandler";

const getIconVision = () => {
  return (
    <div>
      <img src={iconvison} alt="Icono" width="15px" height="18px"></img>
    </div>
  );
};

function RecolectBiometrics(props) {
  const [timetxt, setTimetxt] = useState(true);

  const name = "";
  const texts = [
    [name],
    ["Estamos verificando tu ambiente"],
    ["MIRA TU CÁMARA FIJAMENTE"],
    ["ACÉRCATE UN POCO MÁS A LA CÁMARA", "MÁS CERCA"],
    [
      "DI FUERTE & CLARO",
      "ESTE ES EL COLOR DE MI VOZ",
      "QUEREMOS ESCUCHAR TU VOZ",
    ],
    // ["MUESTRA TU DOCUMENTO DENTRO DEL RECUADRO", "PRIMERO EL FRENTE"],
    // ["AHORA EL REVERSO", "DENTRO DEL RECUADRO"],

    ["AHORA VERIFIQUEMOS TU CELULAR Y CORREO!"],
  ];

  const getTextSteps = (step) => {
    console.log("Step in getTextSteps");
    console.log(step);
    if (step === -1) {
      return (
        <div className="pt-2 text">
          <h2
            className="AVGARDNI"
            style={{ paddingLeft: "12%", paddingRight: "12%" }}
          >
            Estamos procesando tu información
          </h2>
          <LoadingOverlay active={true} spinner={true} />
        </div>
      );
    }
    if (step === 0) {
      return (
        <div className="pt-2 text">
          <h2 className="AVGARDNI">HOLA</h2>
          <h1 className="AVGARDNI pl-2 pr-2">{name}</h1>
        </div>
      );
      // Cara
    } else if (step === 1 || step === 2 || step === 7) {
      return (
        <div className="col-12 text">
          <div className="biometrics">
            {console.log(texts[step][0], "TEXT FACE")}
          

            <img src={iconface} className='icon_face' alt="icon" />
           
            <h1 className="text1_face">{texts[step][0]}</h1>
          </div>
        </div>
      );
    } else if (step === 3) {
      return (
        <div className="col-9 text">
          <div className="row justify-content-center">
            <h1 className="AVGARDNI pl-2 pr-2">{texts[step][0]}</h1>
          </div>
        </div>
      );
    }
    // mano
    // else if(step===3){
    //   return (
    //     <div className="col-12">
    //       <div className="row justify-content-end">
    //       <h2 style={{ right:0 }}>{texts[step][1]}</h2>
    //       </div>

    //       <div className="row justify-content-center">
    //       <h1 style={{ paddingLeft: "25%", paddingRight: "25%" }}>{texts[step][0]}</h1>
    //       </div>

    //     </div>

    //   );
    // }

    // sonrisa
    // else if(step===4){
    //   return (
    //     <div style={{paddingTop: "3%"}}>
    //       <div className="col-12">
    //       <div className="row justify-content-end">

    //       <h1 style={{ paddingLeft: "5%", paddingRight: "5%" }}>{texts[step][0]}</h1>
    //       </div>
    //       </div>
    //     </div>

    //   );
    // }
    // voz
    /* --------------------------CEDULAS FRONT AND BACK MSG---------------------------- */
    /*   else if (step === 5 || step === 6) {
      return (
        <div className="">
          <h2
            className="AVGARDNI"
            style={{
              // right: 0,
              fontSize: 28,
              paddingLeft: "12%",
              paddingRight: "12%",
            }}
          >
            {{texts[step][0]} }
          </h2>
          <h1
            className="AVGARDNI"
            style={{
              // right: 0,
              fontSize: 28,
              paddingLeft: "12%",
              paddingRight: "12%",
            }}
          >
            {texts[step][1]}
          </h1>
        </div>
      );
    } */

    // else if(step===5){
    //   return (
    //     <div className="col-3">
    //     <h2 style={{ right:0 }}>{texts[step][0]}</h2>
    //     <h2 style={{ right:0 }}>{texts[step][1]}</h2>

    //   </div>

    //   );
    // }
    //Voz
    else if (step === 4) {
      return (
        <div className="col-12 text-center pt-lg-2 pt-5 m-lg-0 mt-5">
          <div className="row">
            <div className="col-6">
              <h3 className="AVGARDNI" style={{ right: 0 }}>
                {console.log(texts[step][0], "TEXT FACE")}
                {texts[step][0]}
              </h3>
            </div>
            <div className="col-6">
              <h3 className="AVGARDNI" style={{ right: 0 }}>
                {texts[step][2]}
              </h3>
            </div>
            <div
              className="col-12"
              style={{
                paddingTop: "2%",
                paddingLeft: "25%",
                paddingRight: "25%",
              }}
            >
              <h2 className="AVGARDNI" style={{ right: 0 }}>
                {texts[step][1]}
              </h2>
            </div>
          </div>
        </div>
      );
    } else {
      return;
    }
  };
  return (
    <div
      className="App pt-5 mt-5"
      style={{
        color: "white",
        fontFamily: "Arial",
        fontWeight: "Regular",
        opacity: 0.7,
      }}
    >
      <div className="row justify-content-center pr-2">
        {getTextSteps(props.step)}
      </div>
    </div>
  );
}

export default RecolectBiometrics;
