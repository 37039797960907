import React, { useEffect, useState } from "react";
import WebcamHTML from "../../utils/webcam";
import { useRef } from "react";
import {
  fatalError,
  commonError,
  infoMessage,
  retryMessage,
} from "../../utils/ErrorHandler/errorHandler";

import useQuery from "../../../hooks/useQuery";
import Models from "../../api/api-models";
import { useHistory } from "react-router-dom";

import iconvoice from "./../../../assets/icon_voice.png";
import sound from "./../../../assets/sound.png";
import "./Voice.scss";

function Voice(props) {
  let history = useHistory();
  const query = useQuery();
  const regId = props.registrationId;
  const token = props.token;
  const queryCode = query.get("code");
  const tf = props.tf;

  const webcamRef = useRef(null);
  const [models, setModels] = useState();
  const [wcElement, setWcElement] = useState();

  const [exit, setExit] = useState(false);
  const api = props.api;
  //Async biometrics
  // 5 cedula front, 6 cedula back, 2 cara1, 3 cara2, 4 voz
  const [bioReqs, setBioReqs] = useState([4]);
  // const [bioReqs, setBioReqs] = useState([3, 2, 6, 5]);

  const [getFailsSteps, setFailSteps] = useState([]);
  const [getFailMsg, setFailMsg] = useState([]);
  const [step, setStep] = useState(0);
  const [needsFlujo, setNeedsFlujo] = useState(false);
  const [reqsQueue, setReqsQueue] = useState([]);
  const [capture, setCapture] = useState(false);
  const [record, setRecord] = useState(false);

  const [modelsLoaded, setModelsLoaded] = useState(false);

  const startRecording = () => {
    setRecord(true);
  };



  const nextAtomatic2 = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  //Advances the step
  const nextAtomatic = (ms) => {
    console.log("nextAutomatic: " + ms);
    nextAtomatic2(ms).then(() => document.getElementById("next").click());
  };



  const nextBioStep = () => {
    console.log("NEXT BIO STEP");
    let localStep = reqsQueue.pop();
    console.log(localStep);
    console.log(models);
    updateStep(localStep + 1);
  };

  function addHintMsg(msg) {
    console.log("Adding Hint message!");
    console.log(msg);
    getFailMsg.push(msg);
  }

  const acceptStep = (step2) => {
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
    console.log("Accept Step: " + step2);
    if (bioReqs.indexOf(step2) != -1) bioReqs.splice(bioReqs.indexOf(step2), 1);
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
  };

  function updateStep2() {
    if (step <= 1 && step != -1) {
      console.log("BIO REQS ON START");
      console.log(bioReqs);
      console.log("QUEUE ON START");
      console.log(reqsQueue);
    }
    if (step <= 11) {
      console.log("NEXT BIO STEP");
      let localStep = reqsQueue.pop();
      console.log(localStep);

      if (localStep === 4) {
        // acceptStep(4);
        // nextAtomatic(7000);
      }
      if (bioReqs.length === 0) {
        nextAtomatic(200);
        updateStep(12);
        return;
      } else if (!localStep) {
        wcElement.stopRecording();
        console.log("");
        console.log("Reqs");
        console.log(bioReqs);
        console.log("step");
        console.log(step);
        nextAtomatic(400);
        updateStep(-1);

        return;
      }
      updateStep(localStep);
      //TEMP
    } else {
      updateStep(step);

      if (record === true) {
        // handleStopCaptureClick();
        // stopRecording();
      }

      console.log("updatePage: recolect");
      //Aca poner la siguiente RUTA!!!!
      wcElement.stopCameras().then(() => {
        api.executeCv();
        api.checkLists();
    
         history.push(`/voicemsg?code=${queryCode}`);
        console.log("EXIT FROM BIOMETRICS");
     
      });
      // setExit(true);
      // history.push(`/biometrics?code=${UrlCode}`);
    }
  }

  const repeatStep = (step2) => {
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
    console.log("Repeat Step: " + step2);
    reqsQueue.unshift(step2);
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
    getFailsSteps.push(step2);
  };

  const updateStep = async (stepR) => {
    console.log("updateStep");
    console.log(stepR);
    setStep(stepR);
    if (getFailsSteps.includes(stepR, 0)) {
      let msg = "";
      msg = getFailMsg.pop();

      var res = await retryMessage(msg);
    }
    try {
      switch (stepR) {
        case 0:
          console.log("paso:INICIO");
          break;
        case 1:
          models.predictFilters("filtros");
          break;

          break;
        case 4:
          if (record === false) {
            navigator.mediaDevices
              .getUserMedia({ audio: true })
              .then(() => {
                startRecording();
                nextAtomatic(7000);
                acceptStep(4);
              })

              .catch((e) => {
                api.log("Error: " + e, "startMicrophone()");
                commonError(
                  "No pudimos acceder a tu micrófono, por favor asegurate de dar los permisos nescesarios"
                ).then(() => {
                  updateStep(4);
                });
              });
          }
          console.log("paso:VOZ");
          break;

        default:
          break;
      }
    } catch (e) {
      alert("Error:" + e.toString());
    }
  };

  useEffect(() => {
    console.log("UseEffect RecolectBio");
    loadModels();
  }, []);

  useEffect(() => {
    if (modelsLoaded) updateStep2();
  }, [modelsLoaded]);

  async function loadModels() {
    // await props.modelsLoader.loadModels()
    initCamera();
  }
  async function initCamera() {
    if (reqsQueue.length === 0)
      bioReqs.forEach((val) => {
        reqsQueue.push(val);
      });
    console.log("Init Camera");
    // tf.enableProdMode();
    // await tf.ready();
    let element = new WebcamHTML(
      document.getElementById("webcam"),
      tf,
      api,
      sendToPhone
    );
    setWcElement(element);
    // element.setupBack();
    console.log("REGISTRATION ID IN TF>READY: " + regId);
    console.log("TOKEN IN TF>READY: " + token);
    var res = true;
    // console.log(props.modelsLoader)
    var modelId = props.modelsLoader.modelId;
    var modelFace = props.modelsLoader.modelFace;
    if (!modelId || !modelFace) {
      await props.modelsLoader.loadModels(tf);
      modelId = props.modelsLoader.modelId;
      modelFace = props.modelsLoader.modelFace;
    }
    if (!models) {
      let models2 = new Models(
        token,
        tf,
        element,
        regId,
        // setLoading,
        repeatStep,
        acceptStep,
        nextBioStep,
        null,
        sendToPhone,

        api,
        () => {}, //ShowLoader
        modelId,
        modelFace,
        props.country,
        addHintMsg
      );
      // res = await models2.loadModel();

      setModels(models2);
      element.models = models2;
      setModelsLoaded(true);
    }
  }

  function sendToPhone() {}
  console.log(step);
  return (
    <div>
      <div className="child-3">
        <div className="voice-screen">
          {step == 4 ? (
            <>
              <div style={{ marginTop: "4em" }}>
                <p className="p1">TOMA DE HUELLA BIOMETRICA DE TU VOZ</p>

                <img src={iconvoice} alt="icon" />

                <p className="p2">
                  CUANDO ESTES LIST<br></br>
                  DI CLARAMENTE<br></br>Y EN EN VOZ ALTA<br></br>LA SIGUIENTE
                  FRASE
                </p>
                <p className="p3">
                  ESTE ES EL COLOR<br></br> DE MI VOZ
                </p>
                <img src={sound} alt="icon" />
                <p className="p4">
                  Esta información se utiliza únicamente para validar tu{" "}
                  <br></br>
                  identidad y es guardada de forma segura por anteia.
                </p>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className={"video-stream"}>
        <video
          autoPlay
          playsInline
          id="webcam"
          // playsInline="true"
          audio="false"
          ref={webcamRef}
        />
      </div>
      <button hidden={true} id="next" onClick={() => updateStep2()}></button>
    </div>
  );
}
export default Voice;
