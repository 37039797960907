import React, { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import "./DataDoneScreen/DataDoneScreen.scss";

function CloseWindowScreen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="row dataDone" style={{ color: "white", marginTop: "20%" }}>
      <div className="col-12 verify">
        <h2>Ya puedes cerrar esta ventana</h2>
        <br/>
        <h2>Continua tu registro desde tu computador</h2>
      </div>
    </div>
  );
}
export default CloseWindowScreen;
