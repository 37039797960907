import React, { useEffect, useState } from "react";
import { addEvent } from "../api/Events/EventsApi";
import { useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import ClipLoader from "react-spinners/ClipLoader";
import StorageManager from "../utils/StorageManager";
import "./AgentFinal.scss";
import { TextFieldNew } from "../utils/TextField/TextFieldNew";
import { DateTextField } from "../utils/TextField/DateTextField";
import { Button4, Button5 } from "./../utils/Buttons/Buttons";
import LoadingOverlay from "react-loading-overlay";
import {
  connectToChat,
  getToken,
  initiateSocket,
  sendMessage,
} from "../api/assistant-connection";
import { hintMessageRecorteAgente } from "../utils/ErrorHandler/hintMessages";
import { retryMessage } from "../utils/ErrorHandler/errorHandler";
import Loader from "../utils/Loader/Loader";

export default function AgentFinal({ api, bg1, bg2 }) {
  let history = useHistory();
  const query = useQuery();
  const queryCode = query.get("code");
  const storage = new StorageManager();

  const [sessId, setSessId] = useState("");
  const [timeoutId, setTimeoutId] = useState();
  async function contactAgent() {
    addEvent("AGENT_FINAL_REQUEST", {}, {}, "interaction");
    // if (this.agentCalled) return;
    // this.agentCalled = true;
    console.log("Contacting Agent");
    //get token
    let token = await getToken();
    console.log("Got Token: " + token);
    let id = storage.getId();
    //Initiate socket
    initiateSocket(token);
    //Connect to chat
    connectToChat(handleAgentMsg, handleAgentAccept, false, id);
    startAgentTimer();
  }

  function startAgentTimer() {
    console.log("Starting Agent Timer...");
    stopAgentTimer();
    setTimeoutId(setTimeout(skipAgent.bind(this), 1000 * 120));
    console.log("Agent Timer Started");
  }

  function gotoClose() {
    history.push("/closeWindow");
  }

  function gotoAgent() {
    history.push(`/agentFinal?code=${queryCode}`);
  }

  function skipAgent() {
    addEvent("AGENT_FINAL_SKIP", {}, {}, "interaction");
    api.final(gotoClose, gotoAgent);
  }

  function stopAgentTimer() {
    console.log("Stopping Agent Timer...");
    if (timeoutId !== "") {
      clearTimeout(timeoutId);
    }
  }

  async function handleAgentMsg(error, msg) {
    console.log("AGENT MESSAGE INCOMING");
    console.log(msg);
    console.log("AGENT MESSAGE END");
    let code = "";
    let hint = "";
    let img = "";
    let splt = msg.split("::");

    if (splt.length === 3) {
      code = splt[0];
      img = splt[1];
      hint = splt[2];
    } else if (splt.length > 1) {
      code = splt[0];
      hint = splt[1];
    } else {
      code = "100";
      hint = msg;
    }
    addEvent("AGENT_FINAL_RESPONSE", { code: code }, {}, "interaction");
    // }
    // if (code === "103") {
    //   let res = await hintMessageBorroso(img, bg1, bg2);
    // } else if (code === "102") {
    //   let res = await hintMessageReflejo(img, bg1, bg2);
    // } else
    if (code === "112") {
      console.log(img);
      let res = await hintMessageRecorteAgente(img, bg1, bg2, hint);
    } else {
      let res = await retryMessage(hint, "Volver a capturar");
    }
    console.log("after");
    stopAgentTimer();
    history.push(`/biometrics?code=${queryCode}&skipToEnd`);
  }

  function handleAgentAccept(accepted, id) {
    console.log("Agent accepted: " + accepted);
    console.log("Session ID: " + id);
    setSessId(id);
  }

  useEffect(() => {
    contactAgent();
  }, []);

  return (
    <div
    // className="flex-col align-center justify-center"
    // style={{
    //   height: "100vh",
    //   width: "100vw",
    //   padding: "25px 0 25px 0",
    //   overflow: "scroll",
    // }}
    >
      <LoadingOverlay
        active={true}
        spinner
        text="Espera un momento, estamos verificando tu información"
        classNamePrefix="MyLoader_spinner"
      ></LoadingOverlay>
    </div>
  );
}
