import React from 'react';
import icon1 from "../static/a2censo-SOLO-bvc-logo-BLANCO.png";
import TextFinal from './textFinal';


function InstruccionFinal() {
  

  return (
    <div className="App" style={{ color: "black" }}>
      <div className="row">
       <div className="col-12" style={{ padding: "10%", textAlign: "center", paddingTop: "20%"}}>
        <h5>Bienvenido a</h5>
      <div className="col-12" style={{textAlign: "center"}}>
        <img src={icon1} alt="icono" style={{width: "35%"}}></img>
      </div>
      <br/> 
    <br/>
    </div>
    </div>
    </div>
  );
}


export default InstruccionFinal;
