import axios from "axios";
import { Envs, getEnv } from "../../utils/envSelector";

let url = process.env.REACT_APP_MARCACIONES || "";
let id = process.env.REACT_APP_MARCACIONES_ID || "";
let apiKey = process.env.REACT_APP_MARCACIONES_APIKEY || "";
let auth = {
  username: id,
  password: apiKey,
};
let tokenMethod = "statsCollector/login";
let createEventMethod = "statsCollector/create";

export const addEvent = async (
  eventName: EventNameUnion,
  data: Map<string, string>,
  metadata: Map<string, string>,
  eventType: EventTypeUnion
) => {
  const regId = localStorage.getItem("regId") || "";
  const clientId = localStorage.getItem("clientId") || "";
  const projectId = localStorage.getItem("projectId") || "";
  const env = localStorage.getItem("env") || "";
  const token = await getToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const eventData: Event = {
    clientId,
    regId,
    projectId,
    env,
    data,
    eventName,
    metadata,
    eventType,
  };

  try {
    const response = await axios.post<EventResponse>(
      `${url}/${createEventMethod}`,
      eventData,
      config
    );
    return response.data.success;
  } catch (err) {
    return false;
  }
};

export const setInfo = (regId: string, cliendId: string, projectId: string) => {
  localStorage.setItem("regId", regId);
  localStorage.setItem("clientId", cliendId);
  localStorage.setItem("projectId", projectId);
  switch (getEnv()) {
    case Envs.DEV:
      localStorage.setItem("env", "test");
      break;
    case Envs.PREPROD:
      localStorage.setItem("env", "preprod");
      break;
    case Envs.PROD:
      localStorage.setItem("env", "prod");
      break;
    default:
      localStorage.setItem("env", "test");
      break;
  }
};

export const getToken = async () => {
  try {
    let response = await axios.get<LoginResponse>(`${url}/${tokenMethod}`, {
      auth,
    });
    return response.data.token;
  } catch (err) {
    console.log(err);
    return "";
  }
};

export interface Event {
  eventName: EventNameUnion;
  eventType: EventTypeUnion;
  data: Map<string, string>;
  metadata: Map<string, string>;
  regId: string;
  clientId: string;
  projectId: string;
  env: EnvUnion;
}

export interface LoginResponse {
  token: string;
  expires: number;
}

export interface EventResponse {
  success: boolean;
}

export type EnvUnion = string | "test" | "prod" | "preprod";
export type EventTypeUnion = "interaction" | "error";
export type EventNameUnion =
  | "INITIAL_REG"
  | "GPS_ACCEPT"
  | "GPS_ERROR"
  | "GEOREF_ACCEPT"
  | "GEOREF_ERROR"
  | "DEVICEID_SET"
  | "DEVICEID_ERROR"
  | "INITIAL_DATA"
  | "ADVICE_1"
  | "ADVICE_2"
  | "ADVICE_3"
  | "STEP_DOCUMENT"
  | "CAMERA_BACK_OPEN"
  | "CAMERA_BACK_ERROR"
  | "NEURAL_START"
  | "NEURAL_ERROR"
  | "DOCUMENT_FRONT_SEND"
  | "DOCUMENT_FRONT_ACCEPT"
  | "DOCUMENT_FRONT_REJECT"
  | "DOCUMENT_FRONT_ERROR"
  | "DOCUMENT_FRONT_SKIPPED"
  | "DOCUMENT_BACK_SEND"
  | "DOCUMENT_BACK_ACCEPT"
  | "DOCUMENT_BACK_REJECT"
  | "DOCUMENT_BACK_ERROR"
  | "DOCUMENT_BACK_SKIPPED"
  | "STEP_FACE"
  | "CAMERA_FRONT_OPEN"
  | "CAMERA_FRONT_ERROR"
  | "FACE_SEND"
  | "FACE_ACCEPT"
  | "FACE_REJECT"
  | "FACE_ERROR"
  | "FACE_SKIPPED"
  | "STEP_OTP"
  | "OTP_SENT"
  | "OTP_ERROR"
  | "OTP_CONFIRMED"
  | "OTP_RESEND"
  | "STEP_GEOREF"
  | "GEOREF_SCREEN_ACCEPT"
  | "STEP_EMAIL"
  | "EMAIL_SENT"
  | "EMAIL_ERROR"
  | "EMAIL_CONFIRMED"
  | "EMAIL_RESEND"
  | "AGENT_FINAL_REQUEST"
  | "AGENT_FINAL_RESPONSE"
  | "AGENT_FINAL_SKIP"
  | "END";
