import React, { useState, useEffect } from "react";
import TextFinal from "../textFinal";
import "./IntroPage.scss";
import {
  infoMessage,
  successMessage,
} from "../utils/ErrorHandler/errorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import Loader from "../utils/Loader/Loader";
import icon from "./../../assets/security.png";
import LoadingOverlay from "react-loading-overlay";
function IntroPage(props) {
  const [index, setIndex] = useState(0);
  // const [loader, setLoader] = useState(false)
  console.log("props", props);
  const query = useQuery();
  const UrlCode = query.get("code");
  const history = useHistory();

  function next() {
    setIndex((index) => (index < 1 ? index + 1 : index));
  }

  useEffect(() => {
    console.log("useEffect>>>" + index);
    if (index !== 1) {
      const interval = setInterval(() => {
        setIndex((index) => (index < 1 ? index + 1 : index));
        props.setshowLoader(true);
      }, 5000);
      return () => {
        clearInterval(interval);
      };
    } else {
      loadAll();
    }
  }, [index]);

  async function loadAll() {
    // props.setshowLoader(true);
    // props.api.spoolUp();
    // await props.conexion();
    // history.push(`/recolect?code=${UrlCode}`);
    // console.log("pushed route...");
  }

  return (
    <>
      {props.showLoader ? (
        // <Loader txts={["Vamos a comenzar"]} />
        <LoadingOverlay
          active={true}
          spinner
          text={"Vamos a comenzar"}
          classNamePrefix="MyLoader_spinner"
        />
      ) : (
        <div
          className="App content-text-next introPage"
          style={{ color: "black" }}
        >
          <div className="row">
            <div className="col-12">
              {/* -----------------------------INTRO MESSAGE 1---------------------------------------*/}
              {index === 0 && (
                <div
                  className="col-12 first_text"
                  style={{
                    padding: index !== 2 ? "20%" : "12%",
                    // color: "white",
                    textAlign: "center",
                  }}
                >
                  <TextFinal type="h1" txt={"¡ Hola !"} />
                  <div
                    className="second-text"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h6
                      style={{
                        width: "284px",
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "26px",

                        // color: "#FFFFFF",
                      }}
                    >
                      <TextFinal
                        type="h5"
                        txt={
                          "Queremos que sepas que navegaras en una plataforma segura"
                        }
                      />
                    </h6>
                  </div>
                  <img src={icon} alt="img" className="img-icon" />
                </div>
              )}

              {/* -----------------------------INTRO MESSAGE 3 WITH BUTTON---------------------------------------*/}
              {/*    {index === 1 &&(
         
                <Container className="third_text">
                  <Row>
                    <Col className="col1">
              
                      <TextFinal type="h1" txt={"Es momento"} />
                      <TextFinal type="h1" txt={"de conocerte"} />
                      <TextFinal type="h1" txt={"un poco mas"} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col2">
     
                      <TextFinal type="h1" txt={"Vamos"} />
                      <TextFinal type="h1" txt={"a validar"} />
                      <TextFinal type="h1" txt={"tu identidad"} />
                    </Col>
                  </Row>

            
                </Container>
              )} */}

              {/* -----------------------------START RECOLECT DATA---------------------------------------*/}
              {/* {index === 2 && (
   
            // <RecolectDataStart
            //   nextPage={props.nextPage}
            //   api={props.api}
            //   setPhoneEmail={props.setPhoneEmail}
            //   dataRepo={props.dataRepo}
            // />
            
          )} */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default IntroPage;
