import React, { useEffect, useState } from "react";
import WebcamHTML from "../utils/webcam";
import { useRef } from "react";
import {
  fatalError,
  commonError,
  infoMessage,
  retryMessage,
} from "../utils/ErrorHandler/errorHandler";
import { isMobile } from "../utils/utils";
import useQuery from "../../hooks/useQuery";
import Models from "../api/api-models";
import { useHistory } from "react-router-dom";
import smile from "./../../assets/smilebackground.png";
import boca from "./../../assets/boca.png";
import "./../RecolectDataVideo/RecolectDataVideo.scss";

import { CommandTextsByliveness } from "../utils/TextsVideo/TextsLiveness";

function RecolectDataVideo2(props) {
  // const random = Math.floor(Math.random() * 3) + 1;
  // console.log(random, "Random");
var list = [3, 2]
  let history = useHistory();
  const query = useQuery();
  const regId = props.registrationId;
  const token = props.token;
  const queryCode = query.get("code");
  const tf = props.tf;

  const webcamRef = useRef(null);
  const [models, setModels] = useState();
  const [wcElement, setWcElement] = useState();
  const [videoRostro, setVideoRostro] = useState(false);
  const [styleFace, setStyleFace] = useState(false);
  const api = props.api;
  //Async biometrics
  // 5 cedula front, 6 cedula back, 2 cara1, 3 cara2, 4 voz
  // const [bioReqs, setBioReqs] = useState([6, 5]);
  // const [bioReqs, setBioReqs] = useState([3, 2, 6, 5]);
  const [bioReqs, setBioReqs] = useState([Math.floor((Math.random()*list.length))]);
  //const [bioReqs, setBioReqs] = useState([4, 2]);
  const [getFailsSteps, setFailSteps] = useState([]);
  const [getFailMsg, setFailMsg] = useState([]);
  const [step, setStep] = useState(0);
  const [needsFlujo, setNeedsFlujo] = useState(false);
  const [reqsQueue, setReqsQueue] = useState([]);
  const [capture, setCapture] = useState(false);
  const [loadMati, setLoadMati] = useState(false);
  const [record, setRecord] = useState(false);
  const [colorCuadrito, setColorCuadrito] = useState("#ffffff4f");
  const [showCuadradito, setShowCuadradito] = useState(true);
  const [modelsLoaded, setModelsLoaded] = useState(false);



  const startRecording = () => {
    setRecord(true);
  };

  useEffect(() => {
    if (props.needsPase) {
      bioReqs.unshift(7);
      bioReqs.unshift(8);
    }
    if (props.needsTarjetaPropiedad) {
      bioReqs.unshift(9);
      bioReqs.unshift(10);
    }
  }, []);

  const nextAtomatic2 = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  //Advances the step
  const nextAtomatic = (ms) => {
    console.log("nextAutomatic: " + ms);
    nextAtomatic2(ms).then(() => document.getElementById("next").click());
  };

  function FlujoAlterno(typePass) {
    api.log("Step: " + step, "FlujoAlterno()");
    setCapture(true);
    infoMessage("Toca la pantalla para capturar la imagen");
    setLoadMati(false);
    models.StartCameraCaptureMobile(typePass);
  }

  const nextBioStep = () => {
    console.log("NEXT BIO STEP");
    let localStep = reqsQueue.pop();
    console.log(localStep);
    console.log(models);
    updateStep(localStep + 1);
  };

  function addHintMsg(msg) {
    console.log("Adding Hint message!");
    console.log(msg);
    getFailMsg.push(msg);
  }

  const acceptStep = (step2) => {
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
    console.log("Accept Step: " + step2);
    if (bioReqs.indexOf(step2) != -1) bioReqs.splice(bioReqs.indexOf(step2), 1);
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
  };

  function updateStep2() {
    if (step <= 1 && step != -1) {
      console.log("BIO REQS ON START");
      console.log(bioReqs);
      console.log("QUEUE ON START");
      console.log(reqsQueue);
    }
    if (step <= 11) {
      console.log("NEXT BIO STEP");
      let localStep = reqsQueue.pop();
      console.log(localStep);

      if (localStep === 4) {
        // acceptStep(4);
        // nextAtomatic(7000);
      }
      if (bioReqs.length === 0) {
        nextAtomatic(200);
        updateStep(12);
        return;
      } else if (!localStep) {
        wcElement.stopRecording();
        console.log("");
        console.log("Reqs");
        console.log(bioReqs);
        console.log("step");
        console.log(step);
        nextAtomatic(400);
        updateStep(-1);
        setShowCuadradito(true);
        return;
      }
      updateStep(localStep);
      //TEMP
    } else {
      updateStep(step);

      if (record === true) {
        // handleStopCaptureClick();
        // stopRecording();
      }

      console.log("updatePage: recolect");
      //Aca poner la siguiente RUTA!!!!
      wcElement.stopCameras().then(() => {
        api.executeCv();
        api.checkLists();
        history.push(`/vsms?code=${queryCode}`);
        console.log("EXIT FROM BIOMETRICS");
      });

      // history.push(`/biometrics?code=${UrlCode}`);
    }
  }

  const repeatStep = (step2) => {
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
    console.log("Repeat Step: " + step2);
    reqsQueue.unshift(step2);
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
    getFailsSteps.push(step2);
  };

  const updateStep = async (stepR) => {
    console.log("updateStep");
    console.log(stepR);
    setStep(stepR);
    if (getFailsSteps.includes(stepR, 0)) {
      let msg = "";
      msg = getFailMsg.pop();
      // if (stepR === 5 || stepR === 6 || stepR >= 5) {

      //   // msg = "No pudimos capturar tu documento! Intentemos de nuevo";
      // }
      // if (stepR === 3 || stepR === 2) {

      //   msg =  models.getErrorTextFace(103);
      //   // msg = "No pudimos capturar tu cara correctamente! Intentemos de nuevoooo";
      //   console.log(msg, 'MSG')
      // }
      var res = await retryMessage(msg);
    }
    try {
      switch (stepR) {
        case 0:
          console.log("paso:INICIO");
          break;
        case 1:
          models.predictFilters("filtros");
          break;

        case 2:
          setVideoRostro(true);
          setStyleFace(true);
          needsFlujo ? FlujoAlterno(2) : models.predictface("cara", 2);
          console.log("paso:ROSTRO");
          // drawCuadraditoCedula();
          break;
        case 3:
          setVideoRostro(true);
          setStyleFace(true);
          needsFlujo ? FlujoAlterno(3) : models.predictface("cara", 3);
          console.log("paso:IRIS");
          // drawCuadraditoCedula();
          break;
        case 4:
          setVideoRostro(true);
          if (record === false) {
            navigator.mediaDevices
              .getUserMedia({ audio: true })
              .then(() => {
                startRecording();
                nextAtomatic(7000);
                acceptStep(4);
              })
              .catch((e) => {
                api.log("Error: " + e, "startMicrophone()");
                commonError(
                  "No pudimos acceder a tu micrófono, por favor asegurate de dar los permisos nescesarios"
                ).then(() => {
                  updateStep(4);
                });
              });
          }
          console.log("paso:VOZ");
          break;

        default:
          break;
      }
    } catch (e) {
      alert("Error:" + e.toString());
    }
  };

  useEffect(() => {
    console.log("UseEffect RecolectBio");
    loadModels();
  }, []);

  useEffect(() => {
    if (modelsLoaded) updateStep2();
  }, [modelsLoaded]);

  async function loadModels() {
    // await props.modelsLoader.loadModels()
    initCamera();
  }
  async function initCamera() {
    if (reqsQueue.length === 0)
      bioReqs.forEach((val) => {
        reqsQueue.push(val);
      });
    console.log("Init Camera");
    // tf.enableProdMode();
    // await tf.ready();
    let element = new WebcamHTML(
      document.getElementById("webcam"),
      tf,
      api,
      sendToPhone
    );
    setWcElement(element);
    // element.setupBack();
    console.log("REGISTRATION ID IN TF>READY: " + regId);
    console.log("TOKEN IN TF>READY: " + token);
    var res = true;
    // console.log(props.modelsLoader)
    var modelId = props.modelsLoader.modelId;
    var modelFace = props.modelsLoader.modelFace;
    if (!modelId || !modelFace) {
      await props.modelsLoader.loadModels(tf);
      modelId = props.modelsLoader.modelId;
      modelFace = props.modelsLoader.modelFace;
    }
    if (!models) {
      let models2 = new Models(
        token,
        tf,
        element,
        regId,
        // setLoading,
        drawCuadraditoCedula,
        quitarCuadraditoCecdula,
        repeatStep,
        acceptStep,
        nextBioStep,
        sendToPhone,
        setColorCuadrito,
        api,
        () => {}, //ShowLoader
        modelId,
        modelFace,
        props.country,
        addHintMsg
      );
      // res = await models2.loadModel();

      setModels(models2);
      element.models = models2;
      setModelsLoaded(true);
    }
  }

  function drawCuadraditoCedula() {
    setShowCuadradito(true);
  }

  function quitarCuadraditoCecdula() {
    setShowCuadradito(false);
  }

  function sendToPhone() {}
  console.log(step);
  return (
    <div>
      <div className="child-3">
        <div
          className={
            // seconds === 1 || seconds === 2
            // ? "cont-picture-color"
            // : "cont-picture"
            "cont-picture-liveness"
          }
        >
          {styleFace && (
            <div
              className="inner-face"
              style={
                step == 2 || step == 3
                  ? { border: "1px solid" + colorCuadrito }
                  : { display: "none" }
              }
            >
              <div className="inner-txt-face">
                {(step === 2 || step === 3) && CommandTextsByliveness({ step })}
              </div>
              {step == 2 ? (
                <>
                  <div className="border-face">
                    <img
                      src={smile}
                      alt="face"
                      className="img-border-face-smile"
                    />
                  </div>

                  <div className="inner-txt2-face">
                    <p>Sonrie a la cámara</p>
                  </div>
                </>
              ) : step == 3 ? (
                <>
                  <div className="border-face">
                    <img
                      src={boca}
                      alt="face"
                      className="img-border-face-smile"
                    />
                  </div>

                  <div className="inner-txt2-face">
                    <p>Manten tu boca abierta</p>
                  </div>
                </>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <div className={"video-stream"}>
        <video
          autoPlay
          playsInline
          id="webcam"
          className={videoRostro === true ? "videoFace-liveness" : "nada2"}
          // playsInline="true"
          audio="false"
          ref={webcamRef}
        />
      </div>
      <button hidden={true} id="next" onClick={() => updateStep2()}></button>
    </div>
    //   </Orientation>
    // </DeviceOrientation>
  );
}
export default RecolectDataVideo2;
