import React, { useEffect } from "react";
import "./HuellaBiometrica.scss";
import { Row, Col } from "react-bootstrap";
import icon from "./../../assets/GAFAS.png";
import icon2 from "./../../assets/VARITA.png";
import icon3 from "./../../assets/LUZ.png";
import icon4 from "./../../assets/SOMBRERO.png";
import icon5 from "./../../assets/tapaboca.png";
import img from "./../../assets/verification.png";
import { Button2 } from "../utils/Buttons/Buttons";
import useQuery from "../../hooks/useQuery";
import { Link, useHistory } from "react-router-dom";
import StorageManager from "../utils/StorageManager";
function HuellaBiometrica(props) {
  const query = useQuery();
  const UrlCode = query.get("code");

  // Storage and skipping
  const history = useHistory();
  const storageManager = new StorageManager();
  useEffect(() => {
    let project = storageManager.getProject();
    console.log(project);
    if (project.extraConfig) {
      if (project.extraConfig.showFaceTips === false) {
        history.push(`/videoface?code=${UrlCode}`);
      }
    }
  }, []);

  return (
    <div className="general">
      <div className="huella-container">
        <div className="text-1">
          TOMA DE
          <br />
          HUELLA BIOMÉTRICA
        </div>
        <img src={img} alt="img" className="img-person" />

        <p className="txt-sub-2">
          MANTEN TU ROSTRO
          <br />
          DESCUBIERTO
        </p>

        <div className="div-2">
          <div className="icon-row">
            <div className="icon-div">
              <div className="img-container">
                <img
                  src={icon5}
                  className="img"
                  height={"35px"}
                  width={"50px"}
                  alt="icon"
                />
              </div>
              <p>
                NO USES <br />
                TAPABOCAS
              </p>
            </div>
            <div className="icon-div">
              <div className="img-container">
                <img src={icon} alt="icon" />
              </div>
              <p>
                NO USES
                <br />
                GAFAS
              </p>
            </div>
            <div className="icon-div">
              <div className="img-container">
                <img src={icon4} alt="icon" />
              </div>
              <p>
                NO USES <br />
                SOMBREROS
              </p>
            </div>
          </div>
          <div className="icon-row" style={{ marginTop: "30px" }}>
            <div className="icon-div">
              <div className="img-container">
                <img src={icon2} alt="icon" />
              </div>
              <p>
                NO USES <br />
                EFECTOS
              </p>
            </div>
            <div className="icon-div">
              <div className="img-container">
                <img src={icon3} alt="icon" />
              </div>
              <p>
                ILUMINATE <br />
                BIEN
              </p>
            </div>
          </div>
        </div>
        <div className="div-3">
          <div>
            <Link to={`/videoface?code=${UrlCode}`}>
              <Button2 text="SIGUIENTE" width="177px" height="5vh" />
            </Link>
          </div>
          <p style={{ marginTop: "2em" }}>
            Esta información se utiliza únicamente para la verificación de la
            identidad y será guardada de forma segura por anteia.
          </p>
        </div>
      </div>
    </div>
  );
}
export default HuellaBiometrica;
