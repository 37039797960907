import React, { useEffect, useState } from "react";
import loader from "./../../../assets/loading.png";
import "./Loader.scss";
function Loader(props) {
  const [second, setSecond] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => {
      setSecond(second <= 6 ? second + 1 : second);
    }, 4000);
    return () => clearInterval(timer);
  });
  // console.log(second, "SECOND");

  if (second == 6) {
    setSecond(1);
  }

  if (props.txts) {
    let rows = [];
    props.txts.map((txt, i) => {
      rows.push(
        <p key={i}>
          {txt}
          {/* <br></br> */}
        </p>
      );
    });
    return (
      <div className="loader">
        {/* {console.log("start losader")} */}
        <img src={loader} alt="load" style={{ width: "10%" }} />
        <>{rows}</>
      </div>
    );
  }

  return (
    // {active &&(

    // )}
    <div className="loader">
      {/* {console.log("start losader")} */}
      <img src={loader} alt="load" style={{ width: "10%" }} />
      {second === 1 && (
        <>
          <p>
            Estamos validando<br></br>tu información
          </p>
        </>
      )}
      {second === 2 && (
        <>
          <p>
            Validando <br></br> LIVENESS{" "}
          </p>
        </>
      )}
      {second === 3 && (
        <>
          <p>
            Estamos procesando<br></br>tu información
          </p>
        </>
      )}
      {second === 4 && (
        <>
          <p>
            Validando<br></br>FACE MATCH
          </p>
        </>
      )}
      {second === 5 && (
        <>
          <p>
            Estamos procesando<br></br>tu información
          </p>
        </>
      )}
    </div>
  );
}
export default Loader;
