import React, { useEffect, useState } from "react";
import icono2 from "./../../assets/scan.png";
import "./ReadMore.scss";
import { Button2 } from "./../utils/Buttons/Buttons";
import { useHistory, Link } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { propTypes } from "react-bootstrap/esm/Image";
import { BsTypeH2 } from "react-icons/bs";
function ReadMore(props) {
  let history = useHistory();
  const query = useQuery();
  const UrlCode = query.get("code");
  const [urls, setUrls] = useState([]);
  console.log(props);

  async function getUrls() {
    let project = await props.api.getProjectByCode(UrlCode);
    let urls2 = [];
    if (
      project &&
      project.extraConfig &&
      project.extraConfig.termsAndConditionsList
    ) {
      project.extraConfig.termsAndConditionsList.push({
        url: "https://storage.googleapis.com/anteia-cdn/tyc/politica%20privacidad%20oct%202021.pdf",
        name: "Política de privacidad anteia",
      });
      project.extraConfig.termsAndConditionsList.forEach((element) => {
        console.log(element);
        let elem = (
          <div key={element.name} style={{ marginBottom: "30px" }}>
            <p>{element.name}</p>
            <Button2
              text="Ver"
              width="80px"
              height="40px"
              onClick={() => {
                window.open(element.url, "_blank").focus();
              }}
            />
          </div>
        );
        urls2.push(elem);
      });
      setUrls(urls2);
    }
  }

  useEffect(() => {
    getUrls();
  }, []);

  return (
    <div className="readmore">
      {/* <img src={icono2} className="img-icon2" alt="img" /> */}
      <div>
        <p className="lorem"></p>
        <div>{urls}</div>
        <div style={{ marginTop: "100px" }}>
          <Link to={`/introcheck/?code=${UrlCode}`}>
            <Button2 text="REGRESAR" width="47vw" height="5vh" />
          </Link>
        </div>
      </div>
    </div>
  );
}
export default ReadMore;
