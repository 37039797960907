/**
 * 
 * @returns the current env, DEV if not specified in the .env file
 */
export function getEnv(): string {
  let env = process.env.REACT_APP_ENV;
  if (env) return env;
  else return "DEV";
}

/**
 * 
 * @returns true if is prod, false if not
 */
export function isProd(): boolean {
  if (getEnv() === Envs.PROD) return true;
  else return false;
}

/**
 * 
 * @returns the dataserverUrl for the given ENV, DEV is used by deffault
 */
export function getDataserverUrl(): string | undefined {
  if (getEnv() === Envs.DEV) return process.env.REACT_APP_DATASERVER_DEV;
  if (getEnv() === Envs.PREPROD) return process.env.REACT_APP_DATASERVER_PREPROD;
  if (getEnv() === Envs.PROD) return process.env.REACT_APP_DATASERVER_PROD;
  else return process.env.REACT_APP_DATASERVER_DEV;
}

/**
 * enumerates the various environments
 */
export enum Envs {
  DEV = "DEV",
  PREPROD = "PREPROD",
  PROD = "PROD",
}
