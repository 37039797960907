import React from "react";
import "./App.css";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter as HashRouter, Switch, Route } from "react-router-dom";
import VerifyCode from "./components/VerifyCode";
import AppBar from "./components/AppBar";
import AppRouter from "./AppRouter";

function App() {
  const reload = () => window.location.reload();

  return (
    <div>
      <AppRouter />
    </div>
  );
}

export default App;
