import React from 'react';
import TextFinal from './textFinal';


function VerifyCode() {
 
  const entrar =
  (
    <div className="row justify-content-center" style={{color:"white", textAlign:"center", marginBottom:"3%"}}>
      <div className="col-12">
      <div className="row justify-content-end">
      <button type="button" className="btn btn-default"
        style={{color:"white", padding:"1%",  marginRight:"2%",borderRadius: "20px", marginTop: "1%" }}>
        <h6>¿Ya tienes cuenta?</h6>
      </button>
      <button type="button" className="btn btn-default"
        style={{color:"white", padding:"1%",marginRight:"2%",  borderRadius: "20px", marginTop: "1%" }}>
        <h6>INICIAR SESIÓN</h6>
      </button>
      
      </div>
      </div>
      <div className="col-12" style={{ paddingTop:"12%", paddingLeft: "5%", paddingRight: "5%"}}>
      <TextFinal type="h1" txt="Gracias"/>
      <TextFinal type="h3" txt="Tú correo ha sido verificado."/>
      <TextFinal type="h4" txt="Continua con el proceso."/>

      </div> 
    </div>
  )

  return (
    <div className={ "App2"}>
     <div className="container"> 
     <div className="row"> 
     <div className="col-12">
      {entrar}
      
      </div>    
      <div className="ecu" style={{minHeight: "100px", minWidth: "100%"}}>
        <h6 style={{color:"transparent"}}>Ecualizador</h6>
      </div>
      </div>
      </div>
      </div>
  );
}


export default VerifyCode;
