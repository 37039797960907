function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

/**
 * Checks if the current platform is Android.
 *
 * @returns true if the platform is Android, false otherwise.
 */
function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

/**
 * Checks if the current platform is a mobile device.
 *
 * @returns true if the platform is a mobile device, false otherwise.
 */
function isMobile() {
  return isIOS() || isAndroid();
}

/**
 * Checks if the current environment is Chrome.
 *
 * @returns true if the current environment is Chrome, false otherwise.
 */
function isChrome() {
  return navigator.userAgent.indexOf("Chrome") !== -1;
}

/**
 * Checks if the current environment is Chrome and the platform is iOS.
 *
 * @returns true if the current environment is Chrome + iOS, false otherwise.
 */
function isChromeIOS() {
  return isIOS() && /CriOS/i.test(navigator.userAgent);
}

export { isMobile, isIOS };