import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
// import icon1 from "../../src/static/pass-view-white-asc.png";
import "./RecolectFinal.scss";
import { Container, Col } from "react-bootstrap";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { infoMessage} from "../utils/ErrorHandler/errorHandler";
import {useHistory} from 'react-router-dom'
import useQuery from '../../hooks/useQuery'
import {Button2} from './../utils/Buttons/Buttons'
function RecolectFinal(props) {
 
  let history = useHistory();
  const query = useQuery()
  const UrlCode =  query.get('code')
  const [hide, setHide] = useState(true);
  const [hide2, setHide2] = useState(true);

  const confirst = () => {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  const conSecond = () => {
    var x = document.getElementById("myInput2");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const final = () => {
    var x = document.getElementById("myInput").textContent;
    var y = document.getElementById("myInput2").textContent;
    console.log(x);
    console.log(y);
    console.log(x === y);
    console.log(x === y);
    if (x === y) {
      history.push(`/vemail?code=${UrlCode}`);
  
      // props.nextPage(document.getElementById("myInput").nodeValue);
    } else {
      alert("Las contraseñas deben ser iguales");
    }
  };

  const passhide = () => {
    if (!hide) {
      document.getElementsByClassName("see")[0].style.display = "inherit";
      document.getElementsByClassName("hide")[0].style.display = "none";
    } else {
      document.getElementsByClassName("see")[0].style.display = "none";
      document.getElementsByClassName("hide")[0].style.display = "inherit";
    }
    setHide(!hide);
  };
  const passhide2 = () => {
    if (!hide2) {
      document.getElementsByClassName("see2")[0].style.display = "inherit";
      document.getElementsByClassName("hide2")[0].style.display = "none";
    } else {
      document.getElementsByClassName("see2")[0].style.display = "none";
      document.getElementsByClassName("hide2")[0].style.display = "inherit";
    }
    setHide2(!hide2);
  };

  return (
    <div className="row recolectFinal" style={{ color: "white" }}>
      <Container>
        <Col className="col_final">
          <p className="text_1">Crea tu contraseña</p>
          {/* --------------------FIRST_INPUT_PASS---------------------- */}
          <div className="input_div">
            <input
              placeholder="Contraseña"
              id="myInput"
              type={hide ? "password" : "text"}
            />
            <div className="eyefill">
              <BsFillEyeSlashFill
                onClick={passhide}
                className="see"
                fontSize="23px"
                style={{
                  display: "inherit",
                  color: "rgba(228, 227, 227, 0.596)",
                }}
              />
              <BsFillEyeFill
                onClick={passhide}
                className="hide"
                fontSize="23px"
                style={{ display: "none", color: "rgba(228, 227, 227, 0.596)" }}
              />
            </div>
          </div>
          {/* --------------------SECOND_INPUT_PASS---------------------- */}
          <div className="input_div">
            <input
              placeholder="Confirma tu contraseña"
              id="myInput2"
              type={hide2 ? "password" : "text"}
            />
            <div className="eyefill">
              <BsFillEyeSlashFill
                onClick={passhide2}
                className="see2"
                fontSize="23px"
                style={{
                  display: "inherit",
                  color: "rgba(228, 227, 227, 0.596)",
                }}
              />
              <BsFillEyeFill
                onClick={passhide2}
                className="hide2"
                fontSize="23px"
                style={{ display: "none", color: "rgba(228, 227, 227, 0.596)" }}
              />
            </div>
          </div>
          {/* --------------------TEXTS---------------------- */}
          <p className="text_2">
            Usa minimo 8 carácteres <br />
            combinando mayúsculas, minúsculas,
            <br /> números, un caracter especial
            <br />
            (¡?@*%&)
            <br />
          </p>
          {/* --------------------BUTTON---------------------- */}
          {/* <button className="btn_confirm" onClick={() => final()}>
            <h1>CONFIRMAR</h1>
          </button> */}


          <div style={{ marginTop: "4em" }}>
          <Button2
            text="SIGUIENTE"
            onClick={() => final()}
            width="47vw"
            height="5vh"
          />
        </div>
        </Col>
      </Container>
    </div>
  );
}
export default RecolectFinal;
